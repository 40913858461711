import { Injectable } from "@angular/core";
import { HttpBackend, HttpClient, HttpHeaders, HttpRequest } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { LocalStorageCacheService } from "src/data/local-storage/local-storage-cache.service";
import { Role } from "src/data/models/role.model";
import { environment } from "src/environments/environment";
import { FormGroup } from "@angular/forms";

@Injectable({
  providedIn: 'root'
})
export class JntService {

  private localStorageCacheService: LocalStorageCacheService;

  constructor(
    private httpClient: HttpClient,
    httpBackend: HttpBackend,
    localStorageCacheService: LocalStorageCacheService
  ) {
    this.httpClientNoInterceptor = new HttpClient(httpBackend);
    this.localStorageCacheService = localStorageCacheService;
  }
  private httpClientNoInterceptor: HttpClient;

  private baseAPIUrl = environment.API_URL;

  public getTransactions(url: string): Observable<any> {
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");
    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${url}`, { headers: httpHeaders });
  }

  public expressTracking(billcode: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/JNT/TrackOrder`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

      var json = {
        billcode : billcode
      };

    return this.httpClient.post(`${endPointUrl}`, json, { headers: httpHeaders });
  }

  public getTransaction(requestId : any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/JNT/Transaction/${requestId}`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public cancelTransaction(requestId : any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/JNT/Failed/${requestId}`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

    return this.httpClient.put(`${endPointUrl}`,"",{ headers: httpHeaders });
  }

  public completeTransaction(requestId : any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/JNT/Complete/${requestId}`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

    return this.httpClient.put(`${endPointUrl}`,"",{ headers: httpHeaders });
  }

  public getPayoutTransactionByRequestID(requestId : any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/JNT/PayoutTransaction/${requestId}`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public getCurrencyCollection(): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/JNT/GetCurrencyCollection`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public getIDCollection(): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/JNT/GetID`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }
  
  public getServiceFee(amount: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/JNT/GetServiceFees`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

      var json = {
        amount : amount
      };

    return this.httpClient.post(`${endPointUrl}`, json, { headers: httpHeaders });
  }

  public getAmendFee(amount: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/JNT/GetAmendFees`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

      var json = {
        amount : amount
      };

    return this.httpClient.post(`${endPointUrl}`, json, { headers: httpHeaders });
  }

  public getSourceOfFundCollection(): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/JNT/GetFundSource`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public getOccupationCollection(): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/JNT/GetOccupation`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public getProvinces(): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/JNT/Islands`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public getPlaces(prov: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/JNT/Places`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

      var json = {
        place: prov
      }

    return this.httpClient.post(`${endPointUrl}`, json, { headers: httpHeaders });
  }

  public getBrgy(city: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/JNT/Brgy`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

      var json = {
        place: city
      }

    return this.httpClient.post(`${endPointUrl}`, json, { headers: httpHeaders });
  }

  public getPostal(prov: any, city: any, brgy: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/JNT/Postal`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

      var json = {
        prov: prov,
        city: city,
        brgy: brgy
      }

    return this.httpClient.post(`${endPointUrl}`, json, { headers: httpHeaders });
  }

  public getShipping(sender: any, receiver: any, weight: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/JNT/ShippingFee`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

      var json = {
        senderIsland: sender,
        receiverIsland: receiver,
        weight: weight
      }

    return this.httpClient.post(`${endPointUrl}`, json, { headers: httpHeaders });
  }

  public findClient(findClientFormGroup: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/JNT/FindClient`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

    return this.httpClient.post(`${endPointUrl}`, findClientFormGroup, { headers: httpHeaders });
  }

  public getBeneficiaryBySender(clientID: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/JNT/GetBeneficiaryBySender`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

    var json = {
      senderClientID: clientID
    }

    return this.httpClient.post(`${endPointUrl}`, json, { headers: httpHeaders });
  }

  public addClient(addClientFormGroup: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/JNT/AddClient`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

    return this.httpClient.post(`${endPointUrl}`, addClientFormGroup, { headers: httpHeaders });
  }

  public addBeneficiary(addBeneficiaryFormGroup: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/JNT/addBeneficiary`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

    return this.httpClient.post(`${endPointUrl}`, addBeneficiaryFormGroup, { headers: httpHeaders });
  }

  public processJNT(jntFormGroup: any, itemList: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/JNT/Process`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

      jntFormGroup.items = [itemList];

    return this.httpClient.post(`${endPointUrl}`, jntFormGroup, { headers: httpHeaders });
  }

  public getPayoutTransaction(controlNumber: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/JNT/FindDomesticRemittance`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

      var json = {
        controlNumber : controlNumber
      };

    return this.httpClient.post(`${endPointUrl}`, json, { headers: httpHeaders });
  }

}

