import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { InvoiceComponent } from 'src/app/elements/invoice/invoice.component';
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';
import { PrintService } from 'src/data/services/web/print.service';
import { WebSettingsService } from 'src/data/services/web/web-settings.service';
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { TransactionService } from 'src/data/services/web/transaction.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { JntService } from 'src/data/services/web/jnt.service';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5
import * as html2pdf from 'html2pdf.js';

@Component({
  selector: 'app-jnt-transaction',
  templateUrl: './jnt-transaction.component.html',
  styleUrls: ['./jnt-transaction.component.less']
})
export class JntTransactionComponent implements OnInit {

  @ViewChild(InvoiceComponent ) child: InvoiceComponent ; 
  @ViewChild('vipLogo') vipLogo: ElementRef;

  modalServe: NgbModal;
  previewModal: NgbModalRef;
  
  @Input() requestId: string;
  public data :  any;
  public loader : boolean = false;
  public receipt: any;
  public waybill: any;
  public isA4 = new FormControl();
  roleLevel:any;
  public isTicketActive : any = false;

  public transactionService: TransactionService;
  public dti: any;
  public tin: any;
  public address: any;
  
  constructor(private jntService : JntService, 
    private router: Router,
    private localStorageCacheService : LocalStorageCacheService,
    private currencyPipe : CurrencyPipe,
    public datepipe: DatePipe,
    public printService: PrintService,
    private webService: WebSettingsService, 
    transactionService: TransactionService,
    modalServe: NgbModal) {
    this.transactionService = transactionService;
    PNotifyButtons;
    this.modalServe = modalServe;
  }

  
  @Output("closeModal") closeModal = new EventEmitter();
  
  @Output("getFundTransaction") getFundTransaction = new EventEmitter();
  @ViewChild('myQRTemplate') myQRTemplate: ElementRef;
  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;

  ngOnInit(): void {
    this.loader = true;
    this.getTransaction();
    this.initReceipt();
    this.roleLevel = this.localStorageCacheService.getStorage("role_level");
  }

  openTicket(){

    this.isTicketActive = true;
  }

  initReceipt() {
    this.webService.getWebSettingsByIdentifier("INVOICE_RECEIPT").subscribe((result) => {
      this.receipt = result.data;
    });

    this.webService.getWebSettingsByIdentifier("GoVIPX_QR_TEMPLATE").subscribe((result) => {
      this.waybill = result.data;
    });
  }
  

  response: any;
  isIndividual: any;
  getTransaction() {
    this.jntService.getTransaction(this.requestId).subscribe((result) => {
      //debugger
      this.data = result;
      console.log(result);

      this.loader = false;
      this.initReceiptDetails(result.managerUserId);
    }, error => {
      this.loader = false;
    });
  }

  initReceiptDetails(managerUserId: any){
    this.transactionService.getReceiptDetails(managerUserId).subscribe((result) => {
      this.dti = result.dti;
      this.tin = result.tin != null ? result.tin : "***-***-***-***";
      this.address = result.address;
    });
  }

  makeMoney(money: any) {
    return this.currencyPipe.transform(money, "PHP").replace("PHP", "");
  }


  buildUserPhoto(user: any): any {
    if(user){
      return user.photoFormat + "," + user.photo;
    }
  }

  onBeforePrint(){
    this.previewModal.close();
    //this.closeModal.emit();
  }

  printReceipt(){
    //debugger
    //var resJson = JSON.parse(this.data.responseJson);
    var completedDate = this.data?.createordertime ? this.datepipe.transform(this.data?.createordertime, 'MMM-dd-yyyy') : this.datepipe.transform(this.data?.createordertime, 'MMM-dd-yyyy');
    var transTime = this.data?.createordertime ? this.datepipe.transform(this.data?.createordertime, 'hh:mm:ss a') : this.datepipe.transform(this.data?.createordertime, 'MMM-dd-yyyy');
    var reqId = this.data.requestId;
    var recValNo = this.data?.billerPartnerRefNo ? this.data?.billerPartnerRefNo : null;
    var cashier = this.data.cashierUsername;
    var billerName = "J&T Express";
    var refNo = this.data?.billerPartnerRefNo ? this.data?.billerPartnerRefNo : null ;
    var can = this.data?.mailno;
    var peyeeContact = this.data.payeeContactNumber ? this.data.payeeContactNumber : null;
    var billAmount = this.data.isCOD ? 0.00 : this.data.itemsvalue; 
    var amountPaid =  this.data.isCOD ? 0.00 : this.data.itemsvalue;
    var fee = 0;
    var transNo = this.data.mailno ? this.data.mailno : null;
    var AccountNo = this.data.mailno;
    var billerCode = this.data?.planKey;
    var vipLogo = this.vipLogo.nativeElement.innerHTML;

    if(this.isA4.value){
      // if(this.data[i].statusId == 2){

      // }
      var Newstr = (this.receipt[1].value).replace("{{Date}}", completedDate);
      Newstr = (Newstr).replace("{{Time}}", transTime);
      Newstr = (Newstr).replace("{{Cashier}}", cashier);

      Newstr = (Newstr).replace("{{RefNo}}", refNo);
      Newstr = (Newstr).replace("{{RecValNo}}", recValNo);
      Newstr = (Newstr).replace("{{TransactionNo}}", transNo);
      Newstr = (Newstr).replace("{{PayeeContact}}", peyeeContact);
      
      Newstr = (Newstr).replace("{{AccountNo}}", AccountNo);
      Newstr = (Newstr).replace("{{BillerCode}}", billerCode);
      Newstr = (Newstr).replace("{{BillerName}}", billerName);
      Newstr = (Newstr).replace("{{BillAmount}}", parseFloat(billAmount).toFixed(2));
      Newstr = (Newstr).replace("{{AmountPaid}}", parseFloat(amountPaid).toFixed(2));
      Newstr = Newstr.replace("{{receiptLogo}}", vipLogo);
      Newstr = Newstr.replace("{{dti}}", this.dti);
      Newstr = Newstr.replace("{{tinNo}}", this.tin);
      Newstr = Newstr.replace("{{address}}", this.address);
      
      this.PrintReceipt(Newstr);
      } else {
  
        var Newstr = (this.receipt[0].value).replace("{{Date}}", completedDate);
        Newstr = (Newstr).replace("{{Time}}", transTime);
        Newstr = (Newstr).replace("{{RecID}}", reqId);
        Newstr = (Newstr).replace("{{ReferenceName}}", "Rec Val No.");
        Newstr = (Newstr).replace("{{ServiceRef}}", recValNo);
        Newstr = (Newstr).replace("{{Cashier}}", cashier);
        Newstr = (Newstr).replace("{{BillerName}}", billerName);
        Newstr = (Newstr).replace("{{RefNo}}", refNo);
        Newstr = (Newstr).replace("{{CAN}}", can);
        Newstr = (Newstr).replace("{{PayeeContact}}", peyeeContact);
        Newstr = (Newstr).replace("{{BillAmount}}", parseFloat(billAmount).toFixed(2));
        Newstr = (Newstr).replace("{{billerFee}}", fee);
        Newstr = (Newstr).replace("{{AmountPaid}}", parseFloat(amountPaid).toFixed(2));
        Newstr = (Newstr).replace("{{receiptLogo}}", vipLogo);
        Newstr = (Newstr).replace("{{dti}}", this.dti);
        Newstr = (Newstr).replace("{{tinNo}}", this.tin);
        Newstr = (Newstr).replace("{{address}}", this.address);
        Newstr = (Newstr).replace("Customer Account No", "Mail No");
 
     this.PrintReceipt(Newstr);
   }
  }

  PrintReceipt(Newstr: any)
  {
    var mywindow = window.open('', 'PRINT', 'height=400,width=600');
    mywindow.document.write(Newstr);

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/
    mywindow.print();
    mywindow.close();

    return true;
  }

  waybillRef: NgbModalRef;
  value: any = "04481913131";
  openWaybill(content: any){
    this.waybillRef = this.modalServe.open(content, { centered: true, size: "lg" });
  }

  printWaybill(myQRTemplate: any){
    var qrtemplate = myQRTemplate.innerHTML;

    var Newstr = (this.waybill[0].value)
      .replace("{{QR}}", qrtemplate);
    
    this.convertToPDF(myQRTemplate);
  }

  PrintWaybill(Newstr: any)
  {
    var mywindow = window.open('', 'PRINT', 'height=1000,width=2000');
    mywindow.document.write(Newstr);

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/
    mywindow.print();
    mywindow.close();

    this.modalServe.dismissAll();

    return true;
  }

  convertToPDF(myQRTemplate) {
    //const element = document.getElementById('myQRTemplate');
    console.log(myQRTemplate);
    html2pdf()
      .from(myQRTemplate)
      .set({ html2canvas: { scale: 3 } })
      .toPdf()
      .get('pdf')
      .then(function(pdf) {
        // Save the PDF
        const pdfData = pdf.output('blob');
        const pdfUrl = URL.createObjectURL(pdfData);
        console.log(pdfData);
        // Open the PDF in a new tab
        window.open(pdfUrl, '_blank');
      });
  }

  confirmCancelTransactionRef: NgbModalRef;
  cancelTransaction(content){
    this.confirmCancelTransactionRef = this.modalServe.open(content, { centered: true, size: "md" });
  }

  processCancel() {
    this.loader = true;
    this.jntService.cancelTransaction(this.requestId).subscribe((result) => {
      PNotify.success({
        title: "Information",
        text: "Transaction successfully cancelled.",
      });
      this.loader = false;
      this.getTransaction();
      if (this.getFundTransaction) {
        this.getFundTransaction.emit();
      }
      this.confirmCancelTransactionRef.close();
      this.closeModal.emit();
   }, error => {
    PNotify.error({
      title: "Failed",
      text: "There's a problem occured. Please contant JuanPay support.",
    });
     this.confirmCancelTransactionRef.close();
     this.loader = false;
   });
  }

  confirmPickupRef: NgbModalRef;
  pickupItem(content){
    this.confirmPickupRef = this.modalServe.open(content, { centered: true, size: "md" });
  }

  processPickUp() {
    this.loader = true;
    this.jntService.completeTransaction(this.requestId).subscribe((result) => {
      PNotify.success({
        title: "Information",
        text: "Transaction successfully completed.",
      });
      this.loader = false;
      this.getTransaction();
      if (this.getFundTransaction) {
        this.getFundTransaction.emit();
      }
      this.confirmPickupRef.close();
      this.closeModal.emit();
   }, error => {
    PNotify.error({
      title: "Failed",
      text: "There's a problem occured. Please contant JuanPay support.",
    });
     this.confirmPickupRef.close();
     this.loader = false;
   });
  }

  trackOrderRef: NgbModalRef;
  trackingData: any;
  trackOrder(content, billcode){
    this.jntService.expressTracking(billcode).subscribe((result => {
      if(result.item1){
        this.trackingData = result.item3.responseitems[0];
      }
    }));

    this.trackOrderRef = this.modalServe.open(content, { centered: true, size: "md" });
  }
}
