import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class JuanpayService {

  private localStorageCacheService: LocalStorageCacheService;

  constructor(
    private httpClient: HttpClient,
    httpBackend: HttpBackend,
    localStorageCacheService: LocalStorageCacheService
  ) {
    this.httpClientNoInterceptor = new HttpClient(httpBackend);
    this.localStorageCacheService = localStorageCacheService;
  }
  private httpClientNoInterceptor: HttpClient;

  private baseAPIUrl = environment.API_URL;

  // public getOutlets(prov: any): Observable<any> {
  //   const endPointUrl = `${this.baseAPIUrl}/api/JuanPayOutlet/Places`;
  //   const accessToken: any = this.localStorageCacheService.getStorage("access_token");

  //   const httpHeaders: HttpHeaders = new HttpHeaders()
  //     .append("Authorization", "Bearer " + accessToken)
  //     .append("Content-Type", "application/json");

  //     var json = {
  //       place: prov
  //     }

  //   return this.httpClient.post(`${endPointUrl}`, json, { headers: httpHeaders });
  // }

  public getOutlets(url: string): Observable<any> {
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");
    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${url}`, { headers: httpHeaders });
  }
}
