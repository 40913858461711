import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-outlet-transaction-history',
  templateUrl: './outlet-transaction-history.component.html',
  styleUrls: ['./outlet-transaction-history.component.less']
})
export class OutletTransactionHistoryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
