<!-- <app-loader *ngIf="!accounts || loader"></app-loader> -->
<app-loader *ngIf="loader"></app-loader>

<div id="content" class="flex">
  <!-- ############ Main START-->
  <div>
    <div class="page-hero page-container" id="page-hero">
      <div class="padding d-flex">
        <div class="page-title">
          <h2 class="text-md text-highlight">JuanPay Franchisee</h2>
          <small class="font-italic" style="font-weight: bold">List of JuanPay outlets</small>
        </div>
        <div class="flex"></div>

        <div>
          <!-- <div *ngIf="roleLevelId == 2" (click)="createRequestFund()" class="btn btn-md btn-info">
              <span class="d-none d-sm-inline mx-1">Create New</span>
              <i data-feather="arrow-right"></i>
            </div> -->
        </div>
      </div>
    </div>
    <div class="page-content page-container" id="page-content">
      <div class="">
        <div class="card p-3">
          <div>
            <button
              class="btn btn-primary btn-sm float-right mb-2 mr-2"
              style="width: 150px"
              (click)="openAddCutoff(addOutletModal)"
            >
              Add Outlet &nbsp; <i class="fa fa-plus-circle"></i>
            </button>
          </div>
          <div class="p-2">
            <table
              id="table"
              class="table table-striped table-hover"
              data-plugin="bootstrapTable"
              data-toolbar="#toolbar"
              data-search="true"
              data-search-align="left"
              data-show-export="true"
              data-show-columns="true"
              data-detail-view="false"
              data-mobile-responsive="true"
              data-pagination="true"
              data-page-list="[10, 25, 50, 100, ALL]"
            >
              <thead>
                <tr class="bg-dark">
                  <th data-sortable="true" data-field="owner">User ID</th>
                  <th data-sortable="true" data-field="owner">DTI</th>
                  <th data-sortable="true" data-field="owner">Username</th>
                  <th data-sortable="true" data-field="owner">Email</th>
                  <th data-sortable="true" data-field="id">Activation Date</th>
                  <th data-sortable="true" data-field="owner">Action</th>
                </tr>
              </thead>
              <tbody>
                <!-- *ngFor="
                        let requestFund of requestFunds?.data
                        | paginate: { itemsPerPage: pageRows, currentPage: p, totalItems: requestFunds.totalRecords }; let i = index
                    " account
                    (click)="viewDetails(cutoffModal, requestFund)" -->
                <tr
                  class="odd"
                  role="row"
                  (click)="viewDetails(cutoffModal, outlet)"
                  *ngFor="
                    let outlet of outlets?.data
                      | paginate: { itemsPerPage: 10, currentPage: p, totalItems: outlets.totalRecords };
                    let i = index
                  "
                >
                  <td class="flex">
                    <div class="mb-3 mt-3">
                      {{ outlet.userId }}
                    </div>
                  </td>
                  <td class="flex">
                    <div class="mb-3 mt-3">
                      {{ outlet.dti }}
                    </div>
                  </td>
                  <td class="flex">
                    <div class="mb-3 mt-3">
                      {{ outlet.username }}
                    </div>
                  </td>
                  <td class="flex">
                    <div class="mb-3 mt-3">
                      {{ outlet.email }}
                    </div>
                  </td>
                  <td class="flex">
                    <div class="mb-3 mt-3">
                      {{ outlet.activationDate | date: "MMMM dd, yyyy" }}
                    </div>
                  </td>
                  <td class="flex">
                    <div class="mb-3 mt-3"></div>
                  </td>
                  <!-- <td class="flex">
                    <div class="mb-3 mt-3">
                      <app-status-table-data [status]="outlet.statusId"></app-status-table-data>
                    </div>
                  </td> -->
                </tr>
              </tbody>
            </table>
          </div>

          <div class="row">
            <div class="col-sm-12 col-md-5">
              <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">
                <div class="col-sm-12 col-md-12">
                  <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">
                    Showing {{ (outlets?.pageNumber - 1) * outlets?.pageSize + 1 }} to
                    {{ (outlets?.pageNumber - 1) * outlets?.pageSize + outlets?.data?.length }} of
                    {{ outlets?.data?.length }} entries
                  </div>
                </div>
              </div>
            </div>

            <div class="col-sm-12 col-md-7" style="float: right">
              <div class="dataTables_paginate paging_simple_numbers" id="datatable_paginate">
                <ul class="pagination" style="float: right">
                  <pagination-controls (pageChange)="getOutlets(buildPageUrl($event, 10))"></pagination-controls>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- ############ Main END-->
</div>

<ng-template #cutoffModal let-modal>
  <div class="modal-header pb-0">
    <h6 class="modal-title">Description : {{ selectedCutoff.description }}</h6>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div style="height: 25px">
      <button
        class="btn btn-success btn-sm pull-right float-end float-right"
        *ngIf="selectedCutoff.statusId != 2"
        (click)="openFileUpload(uploadFilemodal)"
      >
        Batch Upload
      </button>
      <button
        class="btn btn-primary btn-sm pull-right float-end float-right mr-2"
        *ngIf="selectedCutoff.statusId != 2"
        (click)="addManual(addEmployeeModal)"
      >
        Add
      </button>
    </div>

    <div class="mt-2">
      <table
        id="table"
        class="table table-striped table-hover mt-2"
        data-plugin="bootstrapTable"
        data-toolbar="#toolbar"
        data-search="true"
        data-search-align="left"
        data-show-export="true"
        data-show-columns="true"
        data-detail-view="false"
        data-mobile-responsive="true"
        data-pagination="true"
        data-page-list="[10, 25, 50, 100, ALL]"
      >
        <thead>
          <tr class="bg-secondary">
            <th>CUT-OFF DATES :</th>
            <td>
              {{ selectedCutoff.startDate | date: "MMMM dd, yyyy" }} -
              {{ selectedCutoff.endDate | date: "MMMM dd, yyyy" }}
            </td>
            <th>REQUESTID :</th>
            <td>{{ selectedCutoff.requestId }}</td>
          </tr>
          <tr class="bg-secondary">
            <th>PROCESSING DATE :</th>
            <td style="border: 0">{{ selectedCutoff.processingDate | date: "MMMM dd, yyyy" }}</td>
            <th style="vertical-align: middle">Status :</th>
            <td style="vertical-align: middle; border: 0">
              <span class="font-weight-bold text-success" *ngIf="selectedCutoff.statusId == 2" style="width: 63px">
                Completed</span
              >
              <span class="font-weight-bold text-warning" *ngIf="selectedCutoff.statusId == 3" style="width: 63px">
                Pending</span
              >
              <span class="font-weight-bold text-info" *ngIf="selectedCutoff.statusId == 7" style="width: 63px">
                Submitted</span
              >
            </td>
          </tr>
        </thead>
      </table>

      <table
        id="table"
        class="table table-striped table-hover mt-2"
        data-plugin="bootstrapTable"
        data-toolbar="#toolbar"
        data-search="true"
        data-search-align="left"
        data-show-export="true"
        data-show-columns="true"
        data-detail-view="false"
        data-mobile-responsive="true"
        data-pagination="true"
        data-page-list="[10, 25, 50, 100, ALL]"
      >
        <thead>
          <tr class="bg-dark">
            <th data-sortable="true" data-field="owner">No</th>
            <th data-sortable="true" data-field="owner">Reference No</th>
            <th data-sortable="true" data-field="owner">Employee/Vips Name</th>
            <th data-sortable="true" data-field="owner">Company Code / Reason</th>
            <th data-sortable="true" data-field="owner">Linked QR</th>
            <th data-sortable="true" data-field="id">GOVIPX ACCOUNT NO</th>
            <th *ngIf="selectedCutoff.statusId != 2" data-sortable="true" data-field="id">GOVIPX Fullname</th>
            <th *ngIf="selectedCutoff.statusId != 2" data-sortable="true" data-field="id">GOVIPX UserType</th>
            <th style="text-align: end" data-sortable="true" data-field="owner">AMOUNT</th>
            <th *ngIf="selectedCutoff.statusId == 2" style="text-align: center" data-sortable="true" data-field="owner">
              STATUS
            </th>
            <th *ngIf="selectedCutoff.statusId != 2" style="text-align: center" data-sortable="true" data-field="owner">
              ACTION
            </th>
          </tr>
        </thead>
        <tbody>
          <!--  -->
          <tr class="odd" role="row" *ngFor="let employee of selecteEmployeeList; let i = index">
            <td class="flex">
              {{ i + 1 }}
            </td>
            <td class="flex">
              {{ employee.referenceNo }}
            </td>
            <td class="flex">
              {{ employee.employeeName }}
            </td>
            <td class="flex">
              {{ employee.companyCode }}
            </td>
            <td class="flex">
              {{ employee.linkedStoreId }}
            </td>
            <td class="flex">
              {{ employee.goVIPXAccountNo }}
            </td>
            <td *ngIf="selectedCutoff.statusId != 2" class="flex">
              {{ employee.name != null ? employee.name : "" }}
            </td>
            <td *ngIf="selectedCutoff.statusId != 2" class="flex">
              {{ employee.statusType != null ? employee.statusType : "" }}
            </td>
            <td class="flex" style="text-align: end">
              {{ employee.amount }}
            </td>
            <td *ngIf="selectedCutoff.statusId == 2" class="flex" style="text-align: end">
              {{ employee.status }}
            </td>

            <td *ngIf="selectedCutoff.statusId != 2" class="flex align-middle" style="text-align: center">
              <button
                class="btn btn-sm btn-danger"
                title="Delete/Remove"
                (click)="removeEmployee(confirmRemoveEmployee, employee)"
              >
                <i class="fa fa-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="modal-body">
    <button
      class="btn btn-sm btn-danger"
      *ngIf="selectedCutoff.statusId == 3"
      title="Delete/Remove"
      (click)="removeCutoff(confirmRemoveCutoff, selectedCutoff)"
    >
      Delete Cut-off &nbsp; <i class="fa fa-trash"></i>
    </button>
    <button
      *ngIf="selectedCutoff.statusId == 3 && selectedCutoff.statusId != 2"
      class="btn btn-sm btn-primary float-right"
      appDebounceClick
      [debounceTime]="500"
      (click)="submitSalary()"
    >
      Submit Salary
    </button>
    <button
      *ngIf="selectedCutoff.statusId == 7 && username == authorizedUsername && selectedCutoff.statusId != 2"
      class="btn btn-sm btn-primary float-right"
      appDebounceClick
      [debounceTime]="500"
      (click)="ProcessSalary()"
    >
      Process Salary
    </button>
    <button
      *ngIf="(selectedCutoff.statusId == 3 || selectedCutoff.statusId == 7) && selectedCutoff.statusId != 2"
      class="btn btn-sm btn-info float-right mr-2"
      appDebounceClick
      [debounceTime]="500"
      (click)="validateEmployeeList()"
    >
      Validate GoVIPX Users
    </button>
  </div>
</ng-template>

<ng-template #addOutletModal let-modal>
  <div *ngIf="outletFormGroup">
    <div class="modal-header">
      <h6 class="modal-title">List of Outlet</h6>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body pt-0">
      <div class="card pl-3 pr-3 mb-0">
        <div class="input-group mb-3">
          <label class="mt-2 mr-2 font-weight-bold">Search by Store name or Username</label>
          <input
            type="text"
            class="form-control"
            placeholder="Search..."
            aria-label="Search"
            [(ngModel)]="searchString"
          />
          <button class="btn btn-primary" type="button" (click)="getOutletsFilter()">
            Search &nbsp; <i class="fa fa-search"></i>
          </button>
        </div>
        <div class="">
          <table
            id="table"
            class="table table-striped table-hover"
            data-plugin="bootstrapTable"
            data-toolbar="#toolbar"
            data-search="true"
            data-search-align="left"
            data-show-export="true"
            data-show-columns="true"
            data-detail-view="false"
            data-mobile-responsive="true"
            data-pagination="true"
            data-page-list="[10, 25, 50, 100, ALL]"
          >
            <thead>
              <tr class="bg-dark">
                <!-- <th data-sortable="true" data-field="owner">User ID</th> -->
                <th data-sortable="true" data-field="owner">Account Details</th>
                <th data-sortable="true" data-field="owner">Outlet Details</th>
                <th data-sortable="true" data-field="owner" width="110px">Action</th>
              </tr>
            </thead>
            <tbody>
              <!-- *ngFor="
                          let requestFund of requestFunds?.data
                          | paginate: { itemsPerPage: pageRows, currentPage: p, totalItems: requestFunds.totalRecords }; let i = index
                      " account
                      (click)="viewDetails(cutoffModal, requestFund)" -->
              <tr
                class="odd"
                role="row"
                *ngFor="
                  let outlet of outletsFilter?.data
                    | paginate: { itemsPerPage: 10, currentPage: pFilter, totalItems: outletsFilter.totalRecords };
                  let i = index
                "
              >
                <!-- <td class="flex">
                  <div class="mb-3 mt-3">
                    {{ outlet.userId }}
                  </div>
                </td> -->

                <td class="flex">
                  <div class="mb-3 mt-3">
                    <span class="text-dark font-weight-bold text-muted">Username:</span> {{ outlet.username }} <br />
                    <span class="text-dark font-weight-bold text-muted">Email:</span> {{ outlet.email }}
                  </div>
                </td>

                <td class="flex">
                  <div class="mb-3 mt-3">
                    <span class="text-dark font-weight-bold text-muted">Store Name / DTI:</span> {{ outlet.storeName }}
                    <br />
                    <span class="text-dark font-weight-bold text-muted">Activation Date:</span>
                    {{ outlet.activationDate | date: "MMMM dd, yyyy" }}
                  </div>
                </td>

                <td class="flex" width="110px">
                  <div class="mb-3 mt-3">
                    <button class="btn btn-primary" (click)="addOutlet(outlet)">Add</button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="row">
          <div class="col-sm-12 col-md-5">
            <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">
              <div class="col-sm-12 col-md-12">
                <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">
                  Showing {{ (outletsFilter?.pageNumber - 1) * outletsFilter?.pageSize + 1 }} to
                  {{ (outletsFilter?.pageNumber - 1) * outletsFilter?.pageSize + outletsFilter?.data?.length }} of
                  {{ outletsFilter?.data?.length }} entries
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-12 col-md-7" style="float: right">
            <div class="dataTables_paginate paging_simple_numbers" id="datatable_paginate1">
              <ul class="pagination" style="float: right">
                <pagination-controls
                  (pageChange)="getOutletsFilter(buildPageUrlFilter($event, 10))"
                ></pagination-controls>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div>
        <!-- <button class="btn btn-sm btn-primary float-right" (click)="processAddCutoff(false)">Add Cut-off date</button> -->
        <button class="btn btn-dark float-right mr-3" aria-label="Close" (click)="modal.dismiss('Cross click')">
          Close
        </button>
      </div>
    </div>

    <div class="modal-body"></div>
  </div>
</ng-template>

<ng-template #addEmployeeModal let-modal>
  <div *ngIf="employeeFormGroup">
    <div class="modal-header">
      <h6 class="modal-title">Add Employee Form</h6>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body">
      <app-textbox
        [parent-form-group]="employeeFormGroup"
        tab-index-start="0"
        control-name="employeeName"
        [error]="employeeFormGroup?.controls.employeeName?.errors | estatus"
        [error-message]="employeeFormGroup?.controls.employeeName?.errors | econtent"
        [read-only]="false"
        label="Employee/Vips Name"
        placeholder="Employee/Vips Name"
      ></app-textbox>

      <app-textbox
        [parent-form-group]="employeeFormGroup"
        tab-index-start="0"
        control-name="companyCode"
        [error]="employeeFormGroup?.controls.companyCode?.errors | estatus"
        [error-message]="employeeFormGroup?.controls.companyCode?.errors | econtent"
        [read-only]="false"
        [max-length]="10"
        label="Company Code / Reason"
        placeholder="Company Code / Reason"
      ></app-textbox>

      <app-textbox
        [parent-form-group]="employeeFormGroup"
        tab-index-start="0"
        control-name="govipxAccountNo"
        [error]="employeeFormGroup?.controls.govipxAccountNo?.errors | estatus"
        [error-message]="employeeFormGroup?.controls.govipxAccountNo?.errors | econtent"
        [read-only]="false"
        label="GoVIPx Account Number"
        placeholder="GoVIPx Account Number"
      ></app-textbox>

      <app-textbox
        [parent-form-group]="employeeFormGroup"
        tab-index-start="0"
        control-name="amount"
        [error]="employeeFormGroup?.controls.amount?.errors | estatus"
        [error-message]="employeeFormGroup?.controls.amount?.errors | econtent"
        [read-only]="false"
        label="Amount"
        placeholder="Amount"
      ></app-textbox>

      <hr />

      <div>
        <button class="btn btn-sm btn-primary float-right" (click)="proceedAddManual()">Add Employee</button>
        <button class="btn btn-sm btn-dark float-right mr-2" aria-label="Close" (click)="modal.dismiss('Cross click')">
          Cancel
        </button>
      </div>
    </div>

    <div class="modal-body"></div>
  </div>
</ng-template>

<ng-template #uploadFilemodal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Upload Files</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div>
      <div class="page-content page-container" id="page-content">
        <div class="padding" *ngIf="fileUploadFormGroup">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <input type="file" (change)="onFileChange($event)" class="custom-file-input" id="inputFile" />
              <label class="custom-file-label" for="inputFile">{{ listFileNames }}</label>
            </div>

            <span *ngIf="fileUploadFormGroup?.controls.reportFile.errors | estatus" class="text-error"
              ><small>{{ fileUploadFormGroup?.controls.reportFile.errors | econtent }}</small>
            </span>
          </div>

          <div class="col-lg-12">
            <table style="width: 100%">
              <tr>
                <th style="text-align: center; color: white; background-color: rgb(79, 79, 79)">Employee Name</th>
                <th style="text-align: center; color: white; background-color: rgb(79, 79, 79)">Company Code</th>
                <th style="text-align: center; color: white; background-color: rgb(79, 79, 79)">GoVIPX Account No</th>
                <th style="text-align: center; color: white; background-color: rgb(79, 79, 79)">Amount</th>
              </tr>
              <tr *ngFor="let item of listItems">
                <td style="text-align: center">{{ item.employeeName }}</td>
                <td style="text-align: center">{{ item.companyCode }}</td>
                <td style="text-align: center">{{ item.govipxAccountNo }}</td>
                <td style="text-align: right">{{ item.amount | number: "1.2-2" }}</td>
              </tr>

              <tr>
                <td style="text-align: center"></td>
                <td style="text-align: center"></td>
                <td style="text-align: center"></td>
                <td style="text-align: center"></td>
              </tr>
              <tr>
                <td style="text-align: right"></td>
                <td style="text-align: right"></td>
                <td style="text-align: right"><strong></strong></td>
                <td style="text-align: right">
                  <strong>Employee Count : {{ listItems.length }}</strong>
                </td>
              </tr>
            </table>
          </div>

          <button class="btn btn-sm btn-primary mt-2 float-right" (click)="submitEmployeeList()">
            <i class="fa fa-upload"></i> &nbsp; Upload Salary
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #confirmRemoveEmployee let-modal>
  <div class="modal-header">
    <h6 class="modal-title">
      Are you sure you want to remove <br />
      <span class="font-weight-bold">{{ selectedToRemoveEmployee.employeeName }}</span> ?
    </h6>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <button class="btn btn-sm btn-success float-right" (click)="processRemoveEmployee(selectedToRemoveEmployee.id)">
      Proceed
    </button>
    <button class="btn btn-sm btn-dark float-right mr-2" aria-label="Close" (click)="modal.dismiss('Cross click')">
      Cancel
    </button>
  </div>
</ng-template>

<ng-template #confirmRemoveCutoff let-modal>
  <div class="modal-header">
    <h6 class="modal-title">
      Are you sure you want to remove <br />
      <span class="font-weight-bold">{{ selectedToRemoveCutoff.description }}</span> ?
    </h6>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <button class="btn btn-sm btn-success float-right" (click)="processRemoveCutoff(selectedToRemoveCutoff.id)">
      Proceed
    </button>
    <button class="btn btn-sm btn-dark float-right mr-2" aria-label="Close" (click)="modal.dismiss('Cross click')">
      Cancel
    </button>
  </div>
</ng-template>
