<app-loader *ngIf="loader"></app-loader>
<div class="modal-body" *ngIf="activeTab != 3">
  <form id=" form" novalidate="">
    <div id="rootwizard">
      <ul class="nav mb-3">
        <li class="nav-item">
          <a class="nav-link text-center" [ngClass]="activeTab == 1 ? 'active' : ''">
            <span class="w-32 d-inline-flex align-items-center justify-content-center circle bg-light active-bg-primary"
              >1</span
            >
            <div class="mt-2">
              <div class="text-muted">J&T</div>
            </div>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-center" [ngClass]="activeTab == 2 ? 'active' : ''">
            <span class="w-32 d-inline-flex align-items-center justify-content-center circle bg-light active-bg-primary"
              >2</span
            >
            <div class="mt-2">
              <div class="text-muted">Confirmation</div>
            </div>
          </a>
        </li>
        <li  class="nav-item" style="margin-left: auto">
          <a class="nav-link text-center" [ngClass]="activeTab == 3 ? 'active' : ''">
            <img [src]="application.value.thumbnail.logo" height="50" class="img-responsive" style="object-fit: contain" />
          </a>
        </li>
      </ul>

      <hr class="bg-dark" />

      <div class="tab-content p-3">
        <div class="tab-pane" [ngClass]="activeTab == 1 ? 'active' : ''" id="selectBillerTab">
          <div class="row" [formGroup]="jntFormGroup">

            <div class="col-sm-6 col-md-6" style="float:left">
                <div class="accordion" id="accordionExample">
                    <div class="card" [formGroup]="senderFormGroup">
                      <div class="card-header p-0" style="background-color: rgb(225, 225, 225);">
                        <h4 class="mb-0">
                          <button class="btn btn-block custom-left-align" type="button">
                            Sender Details 
                          </button>
                        </h4>
                      </div>
                  
                      <div class="card-body pb-0">
                          
                        <app-text-alpha
                            [parent-form-group]="senderFormGroup"
                            tab-index-start="0"
                            control-name="name"
                            upper-case="false"
                            read-only="false"
                            label="Name *"
                            placeholder="Name"
                            max-length="50"
                            [error]="getStatus('name', senderFormGroup)"
                            [error-message]="getErrorMessage('name', senderFormGroup)"
                        >
                        </app-text-alpha>

                        <app-textbox-number-only
                            [parent-form-group]="senderFormGroup"
                            tab-index-start="0"
                            control-name="phone"
                            number-only="true"
                            read-only="false"
                            label="Phone *"
                            placeholder="ex. 09123456789"
                            max-length="11"
                            [error]="getStatus('phone', senderFormGroup)"
                            [error-message]="getErrorMessage('phone', senderFormGroup)"
                        >
                        </app-textbox-number-only>

                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Province: *</label>
                            <div class="col-sm-9">
                                <ng-select
                                  [items]="uniqueProvinces"
                                  [placeholder]="'Select Province'"
                                  [allowClear]="true"
                                  bindLabel="text" 
                                  bindValue="id" 
                                  formControlName="prov"
                                ></ng-select>
                                <span *ngIf="senderFormGroup.controls.prov ? senderFormGroup.controls.prov.touched && senderFormGroup.controls.prov.invalid : false" class="error-msg">
                                  <small>
                                    <div [innerHTML]="getErrorMessage('prov', senderFormGroup)"></div>
                                  </small>
                                </span>
                            </div>
                        </div>

                        <div class="form-group row" *ngIf="senderFormGroup.controls.prov.value">
                          <label class="col-sm-3 col-form-label">City: *</label>
                          <div class="col-sm-9">
                              <ng-select
                                [items]="senderCity"
                                [placeholder]="'Select City'"
                                [allowClear]="true"
                                bindLabel="text" 
                                bindValue="id" 
                                formControlName="city"
                              ></ng-select>
                              <span *ngIf="senderFormGroup.controls.city ? senderFormGroup.controls.city.touched && senderFormGroup.controls.city.invalid : false" class="error-msg">
                                <small>
                                  <div [innerHTML]="getErrorMessage('city', senderFormGroup)"></div>
                                </small>
                              </span>
                          </div>
                        </div>

                        <div class="form-group row" *ngIf="senderFormGroup.controls.city.value">
                          <label class="col-sm-3 col-form-label">Barangay: * </label>
                          <div class="col-sm-9">
                              <ng-select
                                [items]="senderBgry"
                                [placeholder]="'Select Barangay'"
                                [allowClear]="true"
                                bindLabel="text" 
                                bindValue="id" 
                                formControlName="area"
                              ></ng-select>
                              <span *ngIf="senderFormGroup.controls.area ? senderFormGroup.controls.area.touched && senderFormGroup.controls.area.invalid : false" class="error-msg">
                                <small>
                                  <div [innerHTML]="getErrorMessage('area', senderFormGroup)"></div>
                                </small>
                              </span>
                          </div>
                        </div>

                        <!-- <app-textarea
                            [parent-form-group]="senderFormGroup"
                            tab-index-start="0"
                            control-name="address"
                            read-only="false"
                            allowed-dash="true"
                            label="Address *"
                            placeholder="Address"
                            max-length="100"
                            upper-case="true"
                        > 
                        </app-textarea> -->

                        <app-textarea
                          [parent-form-group]="senderFormGroup"
                          tab-index-start="0"
                          control-name="address"
                          read-only="false"
                          label="Address *"
                          placeholder="Address"
                          max-length="150"
                          [error]="getStatus('address', senderFormGroup)"
                          [error-message]="getErrorMessage('address', senderFormGroup)"
                          >
                        </app-textarea>

                    </div>
                    </div>
                    
                    <div class="card" [formGroup]="receiverFormGroup">
                      <div class="card-header p-0" style="background-color: rgb(225, 225, 225);">
                        <h4 class="mb-0">
                          <button class="btn btn-block custom-left-align" type="button">
                            Receiver Details 
                          </button>
                        </h4>
                      </div>
                      <div class="card-body">

                        <app-text-alpha
                            [parent-form-group]="receiverFormGroup"
                            tab-index-start="0"
                            control-name="name"
                            upper-case="false"
                            read-only="false"
                            label="Name *"
                            placeholder="Name"
                            max-length="50"
                            [error]="getStatus('name', receiverFormGroup)"
                            [error-message]="getErrorMessage('name', receiverFormGroup)"
                        >
                        </app-text-alpha>

                        <app-textbox-number-only
                            [parent-form-group]="receiverFormGroup"
                            tab-index-start="0"
                            control-name="phone"
                            number-only="true"
                            read-only="false"
                            label="Phone *"
                            placeholder="ex. 09123456789"
                            max-length="11"
                            [error]="getStatus('phone', receiverFormGroup)"
                            [error-message]="getErrorMessage('phone', receiverFormGroup)"
                        >
                        </app-textbox-number-only>

                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Province: * </label>
                            <div class="col-sm-9">
                              <ng-select
                                [items]="uniqueProvinces"
                                [placeholder]="'Select Province'"
                                [allowClear]="true"
                                bindLabel="text" 
                                bindValue="id" 
                                formControlName="prov"
                              ></ng-select>
                              <span *ngIf="receiverFormGroup.controls.prov ? receiverFormGroup.controls.prov.touched && receiverFormGroup.controls.prov.invalid : false" class="error-msg">
                                <small>
                                  <div [innerHTML]="getErrorMessage('prov', receiverFormGroup)"></div>
                                </small>
                              </span>
                            </div>
                        </div>

                        <div class="form-group row" *ngIf="receiverFormGroup.controls.prov.value">
                          <label class="col-sm-3 col-form-label">City: * </label>
                          <div class="col-sm-9">
                              <ng-select
                                [items]="receiverCity"
                                [placeholder]="'Select City'"
                                [allowClear]="true"
                                bindLabel="text" 
                                bindValue="id" 
                                formControlName="city"
                              ></ng-select>
                              <span *ngIf="receiverFormGroup.controls.city ? receiverFormGroup.controls.city.touched && receiverFormGroup.controls.city.invalid : false" class="error-msg">
                                <small>
                                  <div [innerHTML]="getErrorMessage('city', receiverFormGroup)"></div>
                                </small>
                              </span>
                          </div>
                        </div>

                        <div class="form-group row" *ngIf="receiverFormGroup.controls.city.value">
                          <label class="col-sm-3 col-form-label">Barangay: * </label>
                          <div class="col-sm-9">
                              <ng-select
                                [items]="receiverBgry"
                                [placeholder]="'Select Barangay'"
                                [allowClear]="true"
                                bindLabel="text" 
                                bindValue="id" 
                                formControlName="area"
                              ></ng-select>
                              <span *ngIf="receiverFormGroup.controls.area ? receiverFormGroup.controls.area.touched && receiverFormGroup.controls.area.invalid : false" class="error-msg">
                                <small>
                                  <div [innerHTML]="getErrorMessage('area', receiverFormGroup)"></div>
                                </small>
                              </span>
                          </div>
                        </div>

                        <!-- <app-textarea
                            [parent-form-group]="receiverFormGroup"
                            tab-index-start="0"
                            control-name="address"
                            read-only="false"
                            allowed-dash="true"
                            label="Address *"
                            placeholder="Address"
                            max-length="100"
                            upper-case="true"
                        > 
                        </app-textarea> -->

                        <app-textarea
                          [parent-form-group]="receiverFormGroup"
                          tab-index-start="0"
                          control-name="address"
                          read-only="false"
                          label="Address *"
                          placeholder="Address"
                          max-length="150"
                          [error]="getStatus('address', receiverFormGroup)"
                          [error-message]="getErrorMessage('address', receiverFormGroup)"
                          >
                        </app-textarea>
                    </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-md-6" style="float:left">
                <div class="card" [formGroup]="itemsFormGroup">
                  <div class="card-header p-0" style="background-color: rgb(225, 225, 225);">
                    <h4 class="mb-0">
                      <button class="btn btn-block custom-left-align" type="button">
                        Order Details
                      </button>
                    </h4>
                  </div>

                  <div class="card-body" [formGroup]="jntFormGroup">
                      <app-textbox
                        [parent-form-group]="jntFormGroup"
                        tab-index-start="0"
                        control-name="totalquantity"
                        read-only="true"
                        label="Total Item"
                        placeholder="Total Item"
                        max-length="50"
                        >
                      </app-textbox>

                      <app-textbox-number-only
                        [parent-form-group]="jntFormGroup"
                        tab-index-start="0"
                        control-name="weight"
                        number-only="true"
                        read-only="false"
                        label="Weight (KG) *"
                        placeholder="Total Weight in KG"
                        max-length="10"
                        [error]="getStatus('weight', jntFormGroup)"
                        [error-message]="getErrorMessage('weight', jntFormGroup)"
                      >
                      </app-textbox-number-only>

                      <app-textbox-currency
                        [parent-form-group]="jntFormGroup" 
                        tab-index-start="0"
                        control-name="itemsvalue"
                        [read-only]="false"
                        number-only="false"
                        read-only="true"
                        label="Amount *"
                        placeholder="0.00"
                        max-length="15"
                        [error]="getStatus('itemsvalue', jntFormGroup)"
                        [error-message]="getErrorMessage('itemsvalue', jntFormGroup)"
                      >
                      </app-textbox-currency>

                      <div class="d-flex">
                        <div>
                          <button class="btn btn-primary" (click)="openShippingRateModal(viewImageModal)">
                            Shipping Rates
                          </button>
                        </div>
                        <div class="flex"></div>
                        <!-- <div class=" justify-content-end">
                          <div class="checkbox">
                            <input id="form-checkbox-1" formControlName="isCOD" name="checkbox" type="checkbox">
                            <label for="form-checkbox-1">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 200 200">
                                <mask fill="white" id="checkbox-mask"><rect height="200" width="200"></rect></mask>
                                <rect mask="url(#checkbox-mask)" stroke-width="40" height="200" width="200"></rect>
                                <path stroke-width="15" d="M52 111.018L76.9867 136L149 64"></path>
                              </svg>
                              <span>COD?</span>
                            </label>
                          </div>
                        </div> -->
                        
                      </div>
                    
                  </div>

                    <div class="card-header p-0" style="background-color: rgb(225, 225, 225);">
                      <h4 class="mb-0">
                        <button class="btn btn-block custom-left-align" type="button">
                          Item List
                        </button>
                      </h4>
                    </div>

                    <div class="card-body">
                      <app-textbox
                        [parent-form-group]="itemsFormGroup"
                        tab-index-start="0"
                        control-name="itemname"
                        read-only="false"
                        label="Item Name *"
                        placeholder="Item Name"
                        max-length="50"
                        [error]="getStatus('itemname', itemsFormGroup)"
                        [error-message]="getErrorMessage('itemname', itemsFormGroup)"
                        >
                      </app-textbox>

                      <app-textarea
                        [parent-form-group]="itemsFormGroup"
                        tab-index-start="0"
                        control-name="desc"
                        read-only="false"
                        label="Description *"
                        placeholder="Item Description"
                        max-length="50"
                        [error]="getStatus('desc', itemsFormGroup)"
                        [error-message]="getErrorMessage('desc', itemsFormGroup)"
                        >
                      </app-textarea>

                      <app-textbox-number-only
                        [parent-form-group]="itemsFormGroup"
                        tab-index-start="0"
                        control-name="number"
                        read-only="false"
                        number-only="true"
                        label="No of Items *"
                        placeholder="Number of items"
                        max-length="10"
                        [error]="getStatus('number', itemsFormGroup)"
                        [error-message]="getErrorMessage('number', itemsFormGroup)"
                      >
                      </app-textbox-number-only>

                      <app-textbox-currency
                        [parent-form-group]="itemsFormGroup" 
                        tab-index-start="0"
                        control-name="itemvalue"
                        [read-only]="false"
                        number-only="false"
                        read-only="false"
                        label="Item Value *"
                        placeholder="0.00"
                        max-length="10"
                        [error]="getStatus('itemvalue', itemsFormGroup)"
                        [error-message]="getErrorMessage('itemvalue', itemsFormGroup)"
                      >
                      </app-textbox-currency>

                      <app-textarea
                        [parent-form-group]="jntFormGroup"
                        tab-index-start="0"
                        control-name="remark"
                        read-only="false"
                        label="Remarks"
                        placeholder="Remarks"
                        max-length="50"
                        >
                      </app-textarea>
                      <!-- <button class="btn btn-primary float-right mr-2 mt-2" 
                        (click)="openAddItemModal(addItemModal)">Add Item
                      </button>
                      <table id="datatable" class="table table-striped table-hover" role="grid" aria-describedby="datatable_info">
                        <thead>
                          <tr>
                            <th class="color-blue">Item Name</th>
                            <th class="color-blue text-center">No of Item</th>
                            <th class="color-blue text-center">Weight (KG)</th>
                            <th class="color-blue text-right">Item Value</th>
                            <th class="color-blue text-right"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="
                              let item of itemsList"
                              style="cursor: pointer"
                              class="odd"
                              role="row"
                            >
                              <td class="flex">
                                  <div class="item-except text-sm h-1x">
                                      <span>{{item?.desc}}</span>
                                  </div>
                              </td>
            
                              <td class="flex">
                                  <div class="item-except text-sm h-1x text-center">
                                      <span>{{item?.number}}</span>
                                  </div>
                              </td>

                              <td class="flex">
                                <div class="item-except text-sm h-1x text-center">
                                    <span>{{item?.weight}}</span>
                                </div>
                            </td>
                              
                              <td class="flex">
                                <div class="item-except text-sm h-1x text-right">
                                    <span>{{item?.itemvalue}}</span>
                                </div>
                              </td>

                              <td class="flex">
                                <div class="text-center">
                                  <button class="btn btn-danger" (click)="removeItem(item)"><i class="fa fa-times"></i></button>
                                </div>
                              </td>
                          </tr>
                        </tbody>
                      </table> -->
                    </div>
                  </div>

            </div>
            
          </div>
        </div>

        <div class="tab-pane" [ngClass]="activeTab == 2 ? 'active' : ''" id="newTransTab">
          <div class="form-group" *ngIf="activeTab == 2">
            <p>
              <strong>J&T Confirmation</strong><br />
              <small>Please make sure that the all details is correct.</small>
            </p>
            <div class="card">
              <div class="list list-row row">

                <div class="col-sm-6 col-md-6">
                  <div *ngIf="senderFormGroup">

                    <div class="card-header p-0" style="background-color: rgb(225, 225, 225);">
                      <h4 class="mb-0">
                        <button class="btn btn-block custom-left-align" type="button">
                          Sender Details
                        </button>
                      </h4>
                    </div>

                    <div class="list-item" data-id="19">
                      <div class="flex">
                        <div class="item-date font-weight-bold text-sm d-none d-md-block">Sender Name</div>
                      </div>
                      <div class="no-wrap">
                        <div class="item-feed h-2x">
                          {{ senderFormGroup.controls.name.value.toUpperCase() }}
                        </div>
                      </div>
                    </div>

                    <div class="list-item" data-id="19">
                      <div class="flex">
                        <div class="item-date font-weight-bold text-sm d-none d-md-block">Phone</div>
                      </div>
                      <div class="no-wrap">
                        <div class="item-feed h-2x">
                          {{ senderFormGroup.controls.phone.value }}
                        </div>
                      </div>
                    </div>

                    <div class="list-item" data-id="19">
                      <div class="flex">
                        <div class="item-date font-weight-bold text-sm d-none d-md-block">Province</div>
                      </div>
                      <div class="no-wrap">
                        <div class="item-feed h-2x">
                          {{ senderFormGroup.controls.prov.value }}
                        </div>
                      </div>
                    </div>

                    <div class="list-item" data-id="19">
                      <div class="flex">
                        <div class="item-date font-weight-bold text-sm d-none d-md-block">City</div>
                      </div>
                      <div class="no-wrap">
                        <div class="item-feed h-2x">
                          {{ senderFormGroup.controls.city.value }}
                        </div>
                      </div>
                    </div>

                    <div class="list-item" data-id="19">
                      <div class="flex">
                        <div class="item-date font-weight-bold text-sm d-none d-md-block">Barangay</div>
                      </div>
                      <div class="no-wrap">
                        <div class="item-feed h-2x">
                          {{ senderFormGroup.controls.area.value }}
                        </div>
                      </div>
                    </div>

                    <div class="list-item" data-id="19">
                      <div class="flex">
                        <div class="item-date font-weight-bold text-sm d-none d-md-block">Address</div>
                      </div>
                      <div class="no-wrap">
                        <div class="item-feed h-2x">
                          {{ senderFormGroup.controls.address.value }}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="receiverFormGroup">

                    <div class="card-header p-0" style="background-color: rgb(225, 225, 225);">
                      <h4 class="mb-0">
                        <button class="btn btn-block custom-left-align" type="button">
                          Receiver Details
                        </button>
                      </h4>
                    </div>

                    <div class="list-item" data-id="19">
                      <div class="flex">
                        <div class="item-date font-weight-bold text-sm d-none d-md-block">Receiver Name</div>
                      </div>
                      <div class="no-wrap">
                        <div class="item-feed h-2x">
                          {{ receiverFormGroup.controls.name.value.toUpperCase() }}
                        </div>
                      </div>
                    </div>

                    <div class="list-item" data-id="19">
                      <div class="flex">
                        <div class="item-date font-weight-bold text-sm d-none d-md-block">Phone</div>
                      </div>
                      <div class="no-wrap">
                        <div class="item-feed h-2x">
                          {{ receiverFormGroup.controls.phone.value }}
                        </div>
                      </div>
                    </div>

                    <div class="list-item" data-id="19">
                      <div class="flex">
                        <div class="item-date font-weight-bold text-sm d-none d-md-block">Province</div>
                      </div>
                      <div class="no-wrap">
                        <div class="item-feed h-2x">
                          {{ receiverFormGroup.controls.prov.value }}
                        </div>
                      </div>
                    </div>

                    <div class="list-item" data-id="19">
                      <div class="flex">
                        <div class="item-date font-weight-bold text-sm d-none d-md-block">City</div>
                      </div>
                      <div class="no-wrap">
                        <div class="item-feed h-2x">
                          {{ receiverFormGroup.controls.city.value }}
                        </div>
                      </div>
                    </div>

                    <div class="list-item" data-id="19">
                      <div class="flex">
                        <div class="item-date font-weight-bold text-sm d-none d-md-block">Barangay</div>
                      </div>
                      <div class="no-wrap">
                        <div class="item-feed h-2x">
                          {{ receiverFormGroup.controls.area.value }}
                        </div>
                      </div>
                    </div>

                    <div class="list-item" data-id="19">
                      <div class="flex">
                        <div class="item-date font-weight-bold text-sm d-none d-md-block">Address</div>
                      </div>
                      <div class="no-wrap">
                        <div class="item-feed h-2x">
                          {{ receiverFormGroup.controls.address.value }}
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

                <div class="col-sm-6 col-md-6" *ngIf="jntFormGroup">
                  <div class="card-header p-0" style="background-color: rgb(225, 225, 225);">
                    <h4 class="mb-0">
                      <button class="btn btn-block custom-left-align" type="button">
                        Order Details
                      </button>
                    </h4>
                  </div>

                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Total Item</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ jntFormGroup.controls.totalquantity.value }}
                      </div>
                    </div>
                  </div>

                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Amount</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ jntFormGroup.controls.itemsvalue.value }}
                      </div>
                    </div>
                  </div>

                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Total Weight (KG)</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ jntFormGroup.controls.weight.value }}
                      </div>
                    </div>
                  </div>

                  <!-- <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">COD?</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ jntFormGroup.controls.isCOD.value ? 'Yes' : 'No' }}
                      </div>
                    </div>
                  </div> -->

                  <div class="card-header p-0" style="background-color: rgb(225, 225, 225);">
                    <h4 class="mb-0">
                      <button class="btn btn-block custom-left-align" type="button">
                        Item Details
                      </button>
                    </h4>
                  </div>

                  <div *ngIf="itemsFormGroup">
                    <div class="list-item" data-id="19">
                      <div class="flex">
                        <div class="item-date font-weight-bold text-sm d-none d-md-block">Item Name</div>
                      </div>
                      <div class="no-wrap">
                        <div class="item-feed h-2x">
                          {{ itemsFormGroup.controls.itemname.value }}
                        </div>
                      </div>
                    </div>
  
                    <div class="list-item" data-id="19">
                      <div class="flex">
                        <div class="item-date font-weight-bold text-sm d-none d-md-block">Description</div>
                      </div>
                      <div class="no-wrap">
                        <div class="item-feed h-2x">
                          {{ itemsFormGroup.controls.desc.value }}
                        </div>
                      </div>
                    </div>
  
                    <div class="list-item" data-id="19">
                      <div class="flex">
                        <div class="item-date font-weight-bold text-sm d-none d-md-block">No of Items</div>
                      </div>
                      <div class="no-wrap">
                        <div class="item-feed h-2x">
                          {{ itemsFormGroup.controls.number.value }}
                        </div>
                      </div>
                    </div>

                    <div class="list-item" data-id="19">
                      <div class="flex">
                        <div class="item-date font-weight-bold text-sm d-none d-md-block">Item Value</div>
                      </div>
                      <div class="no-wrap">
                        <div class="item-feed h-2x">
                          {{ itemsFormGroup.controls.itemvalue.value }}
                        </div>
                      </div>
                    </div>

                    <div class="list-item" data-id="19">
                      <div class="flex">
                        <div class="item-date font-weight-bold text-sm d-none d-md-block">Remarks</div>
                      </div>
                      <div class="no-wrap">
                        <div class="item-feed h-2x">
                          {{ jntFormGroup.controls.remark.value }}
                        </div>
                      </div>
                    </div>
                  </div>
                  

                  <!-- <table id="datatable" class="table table-striped table-hover" role="grid" aria-describedby="datatable_info">
                    <thead>
                      <tr>
                        <th class="color-blue">Item Name</th>
                        <th class="color-blue text-center">No of Item</th>
                        <th class="color-blue text-center">Weight (KG)</th>
                        <th class="color-blue text-right">Item Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        *ngFor="
                          let item of itemsList"
                          style="cursor: pointer"
                          class="odd"
                          role="row"
                        >
                          <td class="flex">
                              <div class="item-except text-sm h-1x">
                                  <span>{{item?.desc}}</span>
                              </div>
                          </td>
        
                          <td class="flex">
                              <div class="item-except text-sm h-1x text-center">
                                  <span>{{item?.number}}</span>
                              </div>
                          </td>

                          <td class="flex">
                            <div class="item-except text-sm h-1x text-center">
                                <span>{{item?.weight}}</span>
                            </div>
                        </td>
                          
                          <td class="flex">
                            <div class="item-except text-sm h-1x text-right">
                                <span>{{item?.itemvalue}}</span>
                            </div>
                          </td>

                      </tr>
                    </tbody>
                  </table> -->

                </div>

                <div class="col-sm-12 col-md-12">
                  <hr />
                  <div class="col-sm-12 col-md-6" style="float: none; margin: auto">
                    <div class="item-feed h-2x text-center">ENTER PINCODE</div>
                    <code-input
                      [isCodeHidden]="true"
                      [codeLength]="6"
                      (codeChanged)="onCodeChanged($event)"
                      (codeCompleted)="onCodeCompleted($event)"
                      [code]="code"
                    >
                    </code-input>
                  </div>
                </div>
                <br />
              </div>
            </div>
          </div>
        </div>

        <!-- previous next -->
        <div class="row py-3">
          <div class="col-6">
            <a class="btn btn-white button-previous" (click)="setActiveTabPrev()" *ngIf="activeTab != 1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-arrow-left"
              >
                <line x1="19" y1="12" x2="5" y2="12"></line>
                <polyline points="12 19 5 12 12 5"></polyline>
              </svg>
              &nbsp; Previous
            </a>
          </div>
          <div class="col-6">
            <div class="d-flex justify-content-end">
              <a *ngIf="activeTab != 2" class="btn btn-white button-next" (click)="setActiveTab()">
                Next &nbsp;
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-arrow-right"
                >
                  <line x1="5" y1="12" x2="19" y2="12"></line>
                  <polyline points="12 5 19 12 12 19"></polyline>
                </svg>
              </a>
              <a
                *ngIf="activeTab == 2 && codeStatus"
                appDebounceClick 
                [debounceTime]="500" 
                (debounceClick)="processPayment()"
                class="btn btn-info button-next"
                style="color: white"
              >
                Process Order &nbsp;
                <span class="fa fa-hand-pointer"></span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<ng-template #addItemModal let-modal>
  <app-loader *ngIf="loader"></app-loader>
  <div class="modal-header">
    <h4 class="modal-title">Add Items</h4>
    <button type="button" class="close" aria-label="Close" (click)="closeAddItemModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="card p-3">
    <!-- <app-textbox
      [parent-form-group]="itemsFormGroup"
      tab-index-start="0"
      control-name="desc"
      read-only="false"
      label="Item Name"
      placeholder="Item Description"
      max-length="50"
      >
    </app-textbox>

    <app-textbox-number-only
      [parent-form-group]="itemsFormGroup"
      tab-index-start="0"
      control-name="number"
      read-only="false"
      number-only="true"
      label="No of Items"
      placeholder="Number of items"
      max-length="10"
    >
    </app-textbox-number-only>
    
    <app-textbox-number-only
      [parent-form-group]="itemsFormGroup"
      tab-index-start="0"
      control-name="weight"
      read-only="false"
      number-only="true"
      label="Weight (KG)"
      placeholder="Weight in KG"
      max-length="10"
    >
    </app-textbox-number-only>

    <app-textbox-currency
      [parent-form-group]="itemsFormGroup" 
      tab-index-start="0"
      control-name="itemvalue"
      [read-only]="false"
      number-only="false"
      read-only="false"
      label="Item Value"
      placeholder="0.00"
      max-length="10"
    >
    </app-textbox-currency> -->
    <!-- <hr> -->
    <button class="btn btn-primary float-right" (click)="addItem()">Add Item</button>
  </div>

</ng-template>

<ng-template #viewImageModal let-modal>
  <div class="modal-header">
    <h6 class="modal-title">Shipping Rates</h6>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
      <ngx-extended-pdf-viewer
          [base64Src]="imagePreview"
          backgroundColor="#ffffff"
          height="100%"
          [useBrowserLocale]="true"
          [handTool]="true"
          [showHandToolButton]="false"
          [showSidebarButton]="false"
          [showFindButton]="false"
          [showPagingButtons]="true"
          [showZoomButtons]="true"
          [showPresentationModeButton]="false"
          [showOpenFileButton]="false"
          [showPrintButton]="false"
          [showDownloadButton]="true"
          [filenameForDownload]="false"
          [showBookmarkButton]="false"
          [showSecondaryToolbarButton]="false"
          [showRotateButton]="false"
          [showHandToolButton]="false"
          [showScrollingButton]="false"
          [showSpreadButton]="false"
          [showPropertiesButton]="false"
      >
      </ngx-extended-pdf-viewer>
  </div>
</ng-template>