import { CurrencyPipe } from "@angular/common";
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { CryptoService } from "src/data/crypto/crypto.service";
import { Data } from "src/data/data";
import { LocalStorageCacheService } from "src/data/local-storage/local-storage-cache.service";
import { AuthService } from "src/data/services/authentication/auth.service";
import { ManagerUsersService } from "src/data/services/web/manager-users.service";
import { RolesService } from "src/data/services/web/roles.service";
import { StoreService } from "src/data/services/web/store.service";
// PNotify
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5
import { UserService } from "src/data/services/web/user.service";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { TransactionService } from "src/data/services/web/transaction.service";

import { Location, Marker } from 'src/data/classes/location-interface';     
import { combineLatest } from "rxjs";
import { debounceTime, distinctUntilChanged, tap } from "rxjs/operators";
import { AgmMapSingleComponent } from "../agm-map-single/agm-map-single.component";
import { debug } from "console";
import { FileUploadService } from "src/data/services/web/file-upload.service";
import { saveAs } from 'file-saver';
import { WebSettingsService } from "src/data/services/web/web-settings.service";
import { CashierUsersService } from "src/data/services/web/cashier-users.service";
import { forEach } from "lodash";
import { MsfService } from "src/data/services/web/msf.service";

@Component({
  selector: "app-manager-user-view",
  templateUrl: "./manager-user-view.component.html",
  styleUrls: ["./manager-user-view.component.less"],
})
export class ManagerUserViewComponent implements OnInit {
  @ViewChild(AgmMapSingleComponent) agmMap: AgmMapSingleComponent;
  @ViewChild("confirmSalesAccountActivation") confirmSalesAccountActivation: ElementRef;
  @ViewChild("lockAccountModal") lockAccountModal: ElementRef;
  salesActivationModal: NgbModalRef;
  lockModal: NgbModalRef;

  sendSmsModal
  
  location: Location;
  dragPin : boolean = true;
  public userFormGroup: FormGroup;
  public storeSettingFormGroup: FormGroup;
  public storeAddressFormGroup: FormGroup;
  public storeActivationFormGroup: FormGroup;
  public managerBankSettlementFormGroup: FormGroup;
  public multipleCashiersFormGroup: FormGroup;


  public authService: AuthService;
  public localStorageCacheService: LocalStorageCacheService;
  public rolesService: RolesService;
  public usersService: ManagerUsersService;
  public userService: UserService;
  public storeService: StoreService;
  public transactionService: TransactionService;
  public emptyString = "";
  public roles: any;
  public role: any;
  public modalService: BsModalService;

  public accessToken: string;
  public newManagerUser: boolean;
  public user: any;
  public userId: any;
  public roleLevelId: any;
  public roleId: any;

  public fileUploadService: FileUploadService;
  public managerFiles: any;
  public managerFile: any;
  public managerID: any;
  
  public fileFormGroup: FormGroup;
  public fileUploadFormGroup: FormGroup;
  public base64Photo: string | ArrayBuffer;
  public uploadModal = null;

  public adjustWalletFormGroup: FormGroup;

  public maxCashiersArray: any;
  public settlementBanksArray: any;


  constructor(private changeDetectorRef: ChangeDetectorRef, 
    private route: ActivatedRoute,
    private router: Router,
    private cryptoService: CryptoService,
    private data: Data,
    private currencyPipe: CurrencyPipe,
    private formBuilder : FormBuilder,
    authService: AuthService,
    localStorageCacheService: LocalStorageCacheService,
    rolesService: RolesService,
    usersService: ManagerUsersService,
    storeService: StoreService,
    modalService: BsModalService,
    userService: UserService,
    transactionService: TransactionService,
    private modalServe: NgbModal,
    private webSettingsService : WebSettingsService,
    private cashierUserService : CashierUsersService,
    private msfService: MsfService,
    fileUploadService: FileUploadService
  ) {
    this.fileUploadService = fileUploadService;
    this.authService = authService;
    this.localStorageCacheService = localStorageCacheService;
    this.rolesService = rolesService;
    this.usersService = usersService;
    this.storeService = storeService;
    this.modalService = modalService;
    this.transactionService = transactionService;
    this.userService= userService;
    this.accessToken = this.localStorageCacheService.getStorage("access_token");

    PNotifyButtons; // Initiate PNotify buttons. Important!
  }

  ngOnInit(): void {
    this.roleLevelId = this.localStorageCacheService.getStorage("role_level");
    this.roleId = this.localStorageCacheService.getStorage("roleId");
    
    this.route.params.subscribe((params) => {
      const id = decodeURI(params.id || "");
      this.rolesService.getRoles(2).then((roles) => {
        this.roles = roles;
        if (id) {
          this.userId = this.cryptoService.get(this.data.authentication.privateKey, id);
          this.getManagerUser(this.userId);
          this.getCashierUsersWithRoles(this.userId);
          this.setSettlementBanks();
          this.setMaxCashiers();
          this.getDebitCredit(this.userId);
          this.getManagerFiles(this.userId);
          this.initAdjustWalletFormGroup(this.userId);
        } else {
          this.authService.validateAccessToken(this.roleLevelId).then((res) => {
            const idDefault = this.localStorageCacheService.getStorage("id");
            this.userId = idDefault;
            this.getManagerUser(this.userId);
            this.getCashierUsersWithRoles(this.userId);
            this.getDebitCredit(this.userId);
            this.setSettlementBanks();
            this.setMaxCashiers();
            this.getManagerFiles(this.userId);
          });
        }
      });
    });
    this.initFileUploadFormGroup();
    this.getFileManager();
    this.initMSFPromos();
  }

  fileManager : any;
  getFileManager(){
    this.webSettingsService.getWebSettingsByIdentifier('APPLICATION_META').subscribe((result) => {
      console.log(result);
      this.fileManager = result.data;
    });
  }


  

  public imagePreview: any;
  public photoFormat: any;
  public photo: any;
  public photoFileName: any;
  fileType : any = "IMAGE";

  async preview(content: any,filename : any){
    await this.fileUploadService.GetFileRequirements(this.user.username,filename).then(data =>{
      this.photoFormat = data.photoFormat;
      this.photo = data.photo;
      
      this.photoFileName = filename;
        if(data.photoFormat == 'data:application/pdf;base64'){
          this.imagePreview = data.photo;
          this.fileType = "PDF";
        }else{
          this.imagePreview = `${data.photoFormat},${data.photo}`;
          this.fileType = "IMAGE";
        }
      this.uploadModal = this.modalServe.open(content, { centered: true,size:'xl', backdrop: 'static'});
    });

  }

  checkAvailability(fileNames, username , fileValue){
    if(fileNames){
      return fileNames.filter(x=>x.fileName == username + "'s " + fileValue).length > 0;
    }
  }

  triggerFile(inputFile){
    inputFile.click();
  }

  public onUploadFile(event: any, username , fileValue) {
    const reader = new FileReader();
   
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      this.fileName = file.name;
      reader.onload = async () => {
        this.base64Photo = reader.result;
        if(this.calculateImageSize(this.base64Photo)){
         
          var file = {
            fileName : username + "'s " + fileValue,
            managerUserId : this.user.managerUser.id,
            photoFile : this.base64Photo,
            purpose : "For Requirements",
            username : this.user.username
          }
          await this.fileUploadService.UploadFileSingle(file).then(data =>{
            PNotify.info({
              title: "Files",
              text: "File uploaded sucessfuly.",
            });
            this.getManagerFiles(this.userId);
          }, error => {
            PNotify.error({
              title: "Files",
              text: "Internal server error.",
            });

          });
        
        }else{

          PNotify.error({
                title: "Files",
                text: "File is too big. Must be less than or equal to 2mb.",
              });
        }
      };
    }
  }

  calculateImageSize(base64String) : boolean {
    let padding;
    let inBytes;
    let base64StringLength;
    if (base64String.endsWith('==')) { padding = 2; }
    else if (base64String.endsWith('=')) { padding = 1; }
    else { padding = 0; }

    base64StringLength = base64String.length;
    console.log(base64StringLength);
    inBytes = (base64StringLength / 4) * 3 - padding;
    return inBytes < 2000000;
  }

  initFilesFormGroup(): void {
    this.fileFormGroup = new FormGroup({
      dtiForm: new FormControl(this.emptyString),
      applicationForm: new FormControl(this.emptyString),
      validID1: new FormControl(this.emptyString),
      validID2: new FormControl(this.emptyString),
      unionBankApplicationForm: new FormControl(this.emptyString),
      localRemittanceForm: new FormControl(this.emptyString),
      atmWithdrawalApplicationForm: new FormControl(this.emptyString),
      mayorsPermit: new FormControl(this.emptyString),
      birRegistration: new FormControl(this.emptyString),
      googleMapScreenShot: new FormControl(this.emptyString),
      shopFacadeImages: new FormControl(this.emptyString),
      amlcCertificate: new FormControl(this.emptyString)
    });
  }


  getManagerFiles(id: any){
    this.fileUploadService.getManagerFiles(id).subscribe((result) => {
      this.managerFiles = result;
    });

    //console.log("Manager Files: ",this.managerFiles.data[0]);
  }

  viewContent(content: any, managerFile) : void {
    this.managerFile = managerFile;
    this.modalServe.open(content, { centered: true, size: "lg" });
  }

  // 121.01772755384445
  // 14.657296775994261
  refreshMap(){

    this.loader = true;
    this.usersService.storeAddresses().subscribe((storeAddress) => {
      this.loader = false;
      var markers : Marker[] = [];
      markers.push({
        lat: Number(this.storeAddressFormGroup.controls.latitude.value),
        lng:  Number(this.storeAddressFormGroup.controls.longtitude.value),
        iconGreen : { url: '../../../assets/images/marker_green_40.svg',
              scaledSize: {
                width: 40,
                height: 40
              }
          },
        iconRed : { url: '../../../assets/images/marker_red_40.svg',
            scaledSize: {
              width: 40,
              height: 40
            }
        },
        name : storeAddress.filter(x=>x.latitude == this.storeAddressFormGroup.controls.latitude.value 
          && x.longtitude == this.storeAddressFormGroup.controls.longtitude.value)[0].name,
        showRadius : false,
        meterDistanceInRadius : -1,
        meterDistanceInRoad : -1,
        kmDistanceInRoad : "N/A",
        originAddresses : "N/A",
        destinationAddresses : "N/A",
        iconValue : 1
      });
  
     
  
      storeAddress.filter(x=>x.latitude != this.storeAddressFormGroup.controls.latitude.value 
        && x.longtitude != this.storeAddressFormGroup.controls.longtitude.value).forEach(storeAddress => {
        markers.push({
          lat: Number(storeAddress.latitude),
          lng:  Number(storeAddress.longtitude),
          iconGreen : { url: '../../../assets/images/marker_green_40.svg',
              scaledSize: {
                width: 40,
                height: 40
              }
          },
        iconRed : { url: '../../../assets/images/marker_red_40.svg',
            scaledSize: {
              width: 40,
              height: 40
            }
        },
          name : storeAddress.name,
          showRadius : false,
          meterDistanceInRadius : 0,
          meterDistanceInRoad : -1,
          kmDistanceInRoad : "N/A",
          originAddresses : "N/A",
          destinationAddresses : "N/A",
          iconValue : 2
        });
      });
  
  
      var locationRef: Location = {
        latitude: Number(this.storeAddressFormGroup.controls.latitude.value),
        longitude: Number(this.storeAddressFormGroup.controls.longtitude.value),
        zoom: 15,
        isFullScreen: true,
        markers: markers
      };
  
      this.location = locationRef;
      
      },(error)=>{
        this.loader = false
      });
   
   

   
  }

  updateCoordinates(e){
    this.storeAddressFormGroup.controls.latitude.setValue(e.lat);
    this.storeAddressFormGroup.controls.longtitude.setValue(e.lng);
  }

  debitCredit;
  
  getDebitCredit(id: any): void {
    this.transactionService.getDebitCredit(id).subscribe((result) => {
    this.debitCredit = result;
    
    });
  }

  setMaxCashiers() {
    this.maxCashiersArray = [
      { id: 1, name: "1" },
      { id: 2, name: "2" },
      { id: 3, name: "3" },
      { id: 4, name: "4" },
      { id: 5, name: "5" },
      { id: 6, name: "6" },
      { id: 7, name: "7" },
      { id: 8, name: "8" },
      { id: 9, name: "9" },
      { id: 10, name: "10" },
    ];
  }

  setSettlementBanks() {
    this.settlementBanksArray = [
      { id: "0001", name: "UNION BANK" },
      { id: "0102", name: "ASIA UNITED BANK" },
      { id: "0053", name: "BANCO DE ORO" },
      { id: "0004", name: "BANK OF THE PHILIPPINE ISLANDS" },
      { id: "0030", name: "BDO NETWORK BANK" },
      { id: "0010", name: "CHINABANK" },
      { id: "0035", name: "LANDBANK OF THE PHILS" },
      { id: "0026", name: "METROBANK" },
      { id: "0008", name: "PHILIPPINE NATIONAL BANK" },
      { id: "0670", name: "QUEZON CAPITAL RURAL BANK" },
      { id: "0014", name: "SECURITY BANK" },
    ];
  }

  public isStoreSettingCreated: boolean;
  public isStoreAddressCreated: boolean;
  public isStoreActivationCreated: boolean;
  public isReadyForSettingCreation = false;
  public isReadyForAddressCreation = false;
  public isReadyForActivationCreation = false;

  isExpired: boolean = false;
  getManagerUser(id: any): void {
    this.usersService.getManagerUser(id).subscribe((result) => {
      this.user = result.data;
      this.isStoreSettingCreated = this.user.managerUser.storeSetting != null;
      this.isStoreAddressCreated = this.user.managerUser.storeAddress != null;
      this.isStoreActivationCreated = this.user.managerUser.storeActivation != null;
      this.role = this.roles.filter((x) => x.id === this.user.roleId)[0].name;

      if(result.data.managerUser.mobileActivationDate != null && result.data.managerUser.emailActivationDate != null) {
        const today = new Date();
        var milliseconds = today.getMilliseconds();
        const activationDate = new Date(result.data.managerUser.emailActivationDate);
        var millisecondsActivationDate = activationDate.getMilliseconds();

        if(millisecondsActivationDate < milliseconds){
          this.isExpired = true;
        } else {
          this.isExpired = false;
        }
      } else if(result.data.managerUser.mobileActivationDate == null || result.data.managerUser.emailActivationDate == null) {
        this.isExpired = true;
      }

      this.initUserFormGroup(this.user);
      this.initManagerBankSettlementFormGroup(this.user.managerUser);
      this.initStoreSettingsFormGroup(this.user.managerUser.storeSetting, this.user.isLock);
      this.initStoreAddressFormGroup(this.user.managerUser.storeAddress);
      this.initStoreActivationFormGroup(this.user.managerUser.storeActivation);
      this.initMultipleCashiersFormGroup(this.user.managerUser.multipleCashiers);
    });
  }

  initUserFormGroup(user){
    this.userFormGroup = new FormGroup({
      isDeactivated: new FormControl(user?.isDeactivated)
    });

    this.userFormGroup.controls.isDeactivated.valueChanges.subscribe((e) => {
      this.putIsDeactivated(e, this.userId);
    });
  }

  putIsDeactivated(status:any,managerUserId:any){
    this.cashierUserService.putIsDeactivated(status, managerUserId).subscribe((result) => {
      if(!result.isDeactivated){
        PNotify.success({
          title: "Success",
          text: "Account is now Actived",
        });
      } else if (result.isDeactivated) {
        PNotify.success({
          title: "Success",
          text: "Account is now Deactivated",
        });
      } else {
        PNotify.error({
          title: "Error",
          text: "Something went wrong!",
        });
      }
     });
  }

  cashierUsersWithRolesFormGroup : FormGroup;
  cashiersList ; any;

  getCashierUsersWithRoles(id: any): void {
    this.cashierUserService.getCashierUsersWithRoles(id).subscribe((result) => {
     this.initCashierUsersWithRolesFormGroup(result);
    });
  }

  msfPromos: any[] = [];
  initMSFPromos() {
    this.msfService.getMSF().subscribe((result) => {
      this.msfPromos = result.map(promo => ({
        id: promo.id,
        name: promo.promoCode
      }));
    });
  }

  initCashierUsersWithRolesFormGroup(cashiersList : any){
    this.cashierUsersWithRolesFormGroup = new FormGroup({
      cashiers :   this.formBuilder.array([]),
    });
    cashiersList.forEach(cashier => {
        this.addCashierUsersWithRole(cashier);
    });
  }
  
  addCashierUsersWithRole(cashier): void {
      const cashierUsersWithRole = this.formBuilder.group({
        username: this.formBuilder.control(cashier.username),
        bpn : this.formBuilder.control(cashier.bpn),
        aln : this.formBuilder.control(cashier.aln),
        rn : this.formBuilder.control(cashier.rn),
        in : this.formBuilder.control(cashier.in),
        gs : this.formBuilder.control(cashier.gs),
        on : this.formBuilder.control(cashier.on),
      });
      (this.cashierUsersWithRolesFormGroup.controls.cashiers as FormArray).push(cashierUsersWithRole);

      cashierUsersWithRole.controls.bpn.valueChanges.subscribe(e=>{
        this.putCashierUsersWithRoles("bpn",e,cashier.username,this.userId);
      });
      cashierUsersWithRole.controls.aln.valueChanges.subscribe(e=>{
        this.putCashierUsersWithRoles("aln",e,cashier.username,this.userId);
      });
      cashierUsersWithRole.controls.rn.valueChanges.subscribe(e=>{
        this.putCashierUsersWithRoles("rn",e,cashier.username,this.userId);
      });
      cashierUsersWithRole.controls.in.valueChanges.subscribe(e=>{
        this.putCashierUsersWithRoles("in",e,cashier.username,this.userId);
      });
      cashierUsersWithRole.controls.gs.valueChanges.subscribe(e=>{
        this.putCashierUsersWithRoles("gs",e,cashier.username,this.userId);
      });
      cashierUsersWithRole.controls.on.valueChanges.subscribe(e=>{
        this.putCashierUsersWithRoles("on",e,cashier.username,this.userId);
      });

    }

    putCashierUsersWithRoles(type: any, status:any,actUsername:any,managerUserId:any){
      this.cashierUserService.putCashierUsersWithRoles(type,status,actUsername,managerUserId).subscribe((result) => {
        this.getCashierUsersWithRoles(this.userId);
       });
    }

  loader : any = false;
  resendEmailVerification(id: any): void {
    this.loader = true;
    this.userService.resendEmailVerification(id).subscribe((result) => {
      this.loader = false;
      PNotify.success({
        title: "Success",
        text: "Account activation resend to email successfully.",
      });
    },(error)=>{
      this.loader = false
      PNotify.error({
        title: "Error",
        text: "Request is not possible at this time.",
      });

    });
  }

  backToManagerUsersList(): void {
    this.router.navigate(["acm", "manager-user-list"]);
  }

  editManagerUserProfile(): void {
    const encId = this.cryptoService.set(this.data.authentication.privateKey, this.userId);

    this.router.navigate(["acm", "manager-user-edit", { id: encId }]);
  }

  initManagerBankSettlementFormGroup(managerUser: any) : void {

    this.managerBankSettlementFormGroup = new FormGroup({
      bankSettlementName: new FormControl(managerUser.bankSettlementName),
      bankAccountNumber: new FormControl(managerUser.bankAccountNumber),
      bankAccountName: new FormControl(managerUser.bankAccountName)
    });
  }

 
  initStoreSettingsFormGroup(storeSetting: any, islock: any): void {
    this.storeSettingFormGroup = new FormGroup({
      id: new FormControl(this.isStoreSettingCreated ? storeSetting.id : 0),
      name: new FormControl(this.isStoreSettingCreated ? storeSetting.name : this.emptyString),
      tin: new FormControl(this.isStoreSettingCreated ? storeSetting.tin : this.emptyString),
      ctplAuthkey: new FormControl(this.isStoreSettingCreated ? storeSetting.ctplAuthkey : this.emptyString),
      agentCode: new FormControl(this.isStoreSettingCreated ? storeSetting.agentCode : this.emptyString),
      maxCashiers: new FormControl(this.isStoreSettingCreated ? storeSetting.maxCashiers : 1),
      maxBillsAmountPerDay: new FormControl(
        this.isStoreSettingCreated
          ? this.currencyPipe.transform(storeSetting.maxBillsAmountPerDay, "PHP").replace("PHP", "")
          : "50,000.00"
      ),
      maxRemittanceAmountPerDay: new FormControl(
        this.isStoreSettingCreated
          ? this.currencyPipe.transform(storeSetting.maxRemittanceAmountPerDay, "PHP").replace("PHP", "")
          : "50,000.00"
      ),
      maxEpinsAmountPerDay: new FormControl(
        this.isStoreSettingCreated
          ? this.currencyPipe.transform(storeSetting.maxEpinsAmountPerDay, "PHP").replace("PHP", "")
          : "50,000.00"
      ),
      maxEloadAmountPerDay: new FormControl(
        this.isStoreSettingCreated
          ? this.currencyPipe.transform(storeSetting.maxEloadAmountPerDay, "PHP").replace("PHP", "")
          : "50,000.00"
      ),
      managerUserId: new FormControl(this.user.managerUser.id),
      isLazadaEligible : new FormControl(storeSetting.isLazadaEligible),
      isSalesModuleEnable : new FormControl(storeSetting.isSalesModuleEnable),
      isAccountLock : new FormControl(islock),
      isBoracay : new FormControl(storeSetting.isBoracay),
      storeHours : new FormControl(this.isStoreSettingCreated ? storeSetting.storeHours : this.emptyString)
    });

    this.storeSettingFormGroup.controls.isSalesModuleEnable.valueChanges.subscribe(e=>{
      //
      this.putSalesModule(e,this.userId);
    });

    this.storeSettingFormGroup.controls.isAccountLock.valueChanges.subscribe(e=>{
      //
      this.putAccountUnloock(e,this.userId);
    });

    this.storeSettingFormGroup.controls.isBoracay.valueChanges.subscribe(e=>{
      //
      //this.putAccountUnloock(e,this.userId);
    });

    this.setDefaultAmount(this.storeSettingFormGroup.controls.maxBillsAmountPerDay);
    this.setDefaultAmount(this.storeSettingFormGroup.controls.maxRemittanceAmountPerDay);
    this.setDefaultAmount(this.storeSettingFormGroup.controls.maxEpinsAmountPerDay);
    this.setDefaultAmount(this.storeSettingFormGroup.controls.maxEloadAmountPerDay);
  }

  putAccountUnloock(status:any,managerUserId:any){
    //
    if(!status){
      this.cashierUserService.putAccountLock(false,managerUserId).subscribe((result) => {
        if(result){
          PNotify.error({
            title: "Account has been Locked",
            text: "",
          });
        } else {
          PNotify.success({
            title: "Account has been Unlocked",
            text: "",
          });
        }
       });
    } else {
      this.lockModal = this.modalServe.open(this.lockAccountModal, { centered: true, size: "md" });
    }
  }

  

  putAccountLock(){
    this.cashierUserService.putAccountLock(true, this.userId).subscribe((result) => {
      //if(result){
        PNotify.error({
          title: "Account has been Locked",
          text: "",
        });

        this.lockModal.close();
     });
  }

  putSalesModule(status:any,managerUserId:any){
    
    if(!status){
      this.cashierUserService.putSalesModule(false,managerUserId).subscribe((result) => {
        if(result.isSalesModuleEnable){
          PNotify.success({
            title: "Sales Module Enabled",
            text: "",
          });
        } else {
          PNotify.notice({
            title: "Sales Module Disabled",
            text: "",
          });
        }
       });
    } else {
      this.lockModal = this.modalServe.open(this.confirmSalesAccountActivation, { centered: true, size: "md" });
    }
  }

  activateSalesModule(){
    this.cashierUserService.putSalesModule(true, this.userId).subscribe((result) => {
      if(result.isSalesModuleEnable){
        PNotify.success({
          title: "Sales Module Enabled",
          text: "",
        });
        this.salesActivationModal.close();
      } else {
        PNotify.notice({
          title: "Sales Module Disabled",
          text: "",
        });
      }
     });
  }

  setDefaultAmount(control: AbstractControl) {
    control.valueChanges.subscribe((element) => {
    
      if (!element) {
        control.setValue("50,000.00");
      }
    });
  }
  initStoreAddressFormGroup(storeAddress: any): void {
      this.storeAddressFormGroup = new FormGroup({
        id: new FormControl(this.isStoreAddressCreated ? storeAddress.id : 0),
        number: new FormControl(this.isStoreAddressCreated ? storeAddress.number : this.emptyString),
        street: new FormControl(this.isStoreAddressCreated ? storeAddress.street : this.emptyString),
        subdivision: new FormControl(this.isStoreAddressCreated ? storeAddress.subdivision : this.emptyString),
        barangay: new FormControl(this.isStoreAddressCreated ? storeAddress.barangay : this.emptyString),
        municipality: new FormControl(this.isStoreAddressCreated ? storeAddress.municipality : this.emptyString),
        city: new FormControl(this.isStoreAddressCreated ? storeAddress.city : this.emptyString),
        region: new FormControl(this.isStoreAddressCreated ? storeAddress.region : this.emptyString),
        longtitude: new FormControl(this.isStoreAddressCreated ? storeAddress.longtitude : this.emptyString),
        latitude: new FormControl(this.isStoreAddressCreated ? storeAddress.latitude :this.emptyString),
        spm: new FormControl(this.isStoreAddressCreated && storeAddress.spm ? storeAddress.spm : this.emptyString),
        managerUserId: new FormControl(this.user.managerUser.id),
      });
  }

  

  initStoreActivationFormGroup(storeActivation: any): void {
    this.storeActivationFormGroup = new FormGroup({
      id: new FormControl(this.isStoreActivationCreated ? storeActivation.id : 0),
      mainActivationDate: new FormControl(
        this.isStoreActivationCreated ? storeActivation.mainActivationDate : this.emptyString
      ),
      enterpriseActivationDate: new FormControl(
        this.isStoreActivationCreated ? storeActivation.enterpriseActivationDate : this.emptyString
      ),
      sponsor: new FormControl(this.isStoreActivationCreated ? storeActivation.sponsor : this.emptyString),
      upline: new FormControl(this.isStoreActivationCreated ? storeActivation.upline : this.emptyString),
      placement: new FormControl(this.isStoreActivationCreated ? storeActivation.placement : this.emptyString),
      accountType: new FormControl(this.isStoreActivationCreated ? storeActivation.accountType : this.emptyString),
      MSFPromoId: new FormControl(this.isStoreActivationCreated ? storeActivation.msfPromo.id : this.emptyString),
      storeType: new FormControl(this.isStoreActivationCreated ? storeActivation.storeType : this.emptyString),
      managerUserId: new FormControl(this.user.managerUser.id),
    });
  }

  initMultipleCashiersFormGroup(isActive : any){
    this.multipleCashiersFormGroup = new FormGroup({
      status: new FormControl(isActive)
    });
    this.multipleCashiersFormGroup.controls.status.valueChanges.subscribe(
      (result) => {
      this.usersService.putManagerUserMultipleCashiers(this.userId, this.multipleCashiersFormGroup.controls.status.value).subscribe(
        (result) => {
          PNotify.success({
            title: "Updated Successfully",
            text: "",
          });
        });
      });
  }

  initFileUploadFormGroup(): void {
    this.fileUploadFormGroup = new FormGroup({
      photoFile: new FormControl(this.emptyString),
      purpose: new FormControl(this.emptyString),
      meta: new FormControl(this.emptyString)
    });
  }
  
  initAdjustWalletFormGroup(userId: any): void {
    this.adjustWalletFormGroup = new FormGroup({
      remarks: new FormControl(this.emptyString),
      adjustmentAmount: new FormControl(this.emptyString),
      userId: new FormControl(userId),
      isAdd: new FormControl(this.emptyString)
    });
  }

  checkMemberDetails() {
    this.usersService.getManagerVIPDetails(this.user.username).subscribe(
      (result) => {
        if (result.Username) {
          this.storeActivationFormGroup.controls.mainActivationDate.setValue(result.Created_Dt);
          this.storeActivationFormGroup.controls.enterpriseActivationDate.setValue(result.Created_Dt);
          this.storeActivationFormGroup.controls.sponsor.setValue(result.Sponsored_Id);
          this.storeActivationFormGroup.controls.upline.setValue(result.Upline_Id);
          this.storeActivationFormGroup.controls.placement.setValue(result.Placement);
          this.storeActivationFormGroup.controls.accountType.setValue(result.Reg_Type);
          this.storeActivationFormGroup.controls.storeType.setValue("Retail Store");
        } else {
          PNotify.error({
            title: "Connecting to VIP",
            text: "Username does not exist.",
          });
        }
      },
      (error) => {
        PNotify.error({
          title: "Connecting to VIP",
          text: "Username does not exist.",
        });
      }
    );
  }

  public processStoreSave = false;

  actionEventCreateManagerStoreSetting(): void {
    this.processStoreSave = true;

    if (this.isStoreSettingCreated) {
      this.actionEvenUpdateManagerStoreSetting();
    } else {
      this.usersService.createManagerStore(this.storeSettingFormGroup.value).subscribe(
        (result) => {
          this.isStoreSettingCreated = true;
          this.getManagerUser(this.userId);
          this.processStoreSave = false;
          PNotify.success({
            title: "Success",
            text: "Manager account successfully created.",
          });
        },
        (error) => {
          if (error.status === 400) {

            const obj = error.error.errors;
            for (const key in obj) {
              if (obj.hasOwnProperty(key)) {
                let str: string = key;
                str = str[0].toLowerCase() + str.slice(1);
                this.storeSettingFormGroup.controls[str].setErrors({
                  required: Validators.required,
                  apiMessage: obj[key],
                });
              }
            }
            this.processStoreSave = false;
          } else {
            // Other error
            this.processStoreSave = false;
          }
        }
      );
    }
  }

  actionEvenUpdateManagerStoreSetting(): void {
    this.processStoreSave = true;
    var lazada = this.storeSettingFormGroup.controls.isLazadaEligible.value;
 
    
    this.usersService
      .updateManagerStore(this.storeSettingFormGroup.value, this.storeSettingFormGroup.value.id)
      .subscribe(
        (result) => {
          this.isStoreSettingCreated = true;
          this.getManagerUser(this.userId);
          this.processStoreSave = false;
          PNotify.success({
            title: "Success",
            text: "Manager store successfully updated.",
          });
        },
        (error) => {
          if (error.status === 400) {
            const obj = error.error.errors;
            for (const key in obj) {
              if (obj.hasOwnProperty(key)) {
                let str: string = key;
                str = str[0].toLowerCase() + str.slice(1);
                this.storeSettingFormGroup.controls[str].setErrors({
                  required: Validators.required,
                  apiMessage: obj[key],
                });
              }
            }
            this.processStoreSave = false;
          } else {
            // Other error
            this.processStoreSave = false;
          }
        }
      );
  }

  actionEventCreateManagerStoreAddress(): void {
    if (this.isStoreAddressCreated) {
      this.actionEventUpdateManagerStoreAddress();
    } else {
     
      this.usersService.createManagerStoreAddress(this.storeAddressFormGroup.value).subscribe(
        (result) => {
          this.isStoreAddressCreated = true;
          this.getManagerUser(this.userId);
          this.processStoreSave = false;
          PNotify.success({
            title: "Success",
            text: "Manager store address successfully updated.",
          });
        },
        (error) => {
          if (error.status === 400) {
            const obj = error.error.errors;
            for (const key in obj) {
              if (obj.hasOwnProperty(key)) {
                let str: string = key;
                str = str[0].toLowerCase() + str.slice(1);
                this.storeAddressFormGroup.controls[str].setErrors({
                  required: Validators.required,
                  apiMessage: obj[key],
                });
              }
            }
            this.processStoreSave = false;
          } else {
            // Other error
            this.processStoreSave = false;
          }
        }
      );
    }
  }

  actionEventUpdateManagerBankSettlement(): void {
    this.processStoreSave = true;
    this.usersService
      .editManagerUserBankSettlement(this.managerBankSettlementFormGroup.value, this.userId)
      .subscribe(
        (result) => {
          PNotify.success({
            title: "Success",
            text: "Manager bank settlement successfully updated.",
          });
          this.processStoreSave = false;
        },
        (error) => {
          PNotify.error({
            title: "Failed",
            text: "Manager bank settlement update failed. Please contact IT team.",
          });
            this.processStoreSave = false;
        }
      );
  }


  actionEventUpdateManagerStoreAddress(): void {
    this.usersService
      .updateManagerStoreAddress(this.storeAddressFormGroup.value, this.storeAddressFormGroup.value.id)
      .subscribe(
        (result) => {
          this.isStoreAddressCreated = true;
          this.getManagerUser(this.userId);
          this.processStoreSave = false;
          PNotify.success({
            title: "Success",
            text: "Manager store address successfully updated.",
          });
        },
        (error) => {
          if (error.status === 400) {
            const obj = error.error.errors;
            for (const key in obj) {
              if (obj.hasOwnProperty(key)) {
                let str: string = key;
                str = str[0].toLowerCase() + str.slice(1);
                this.storeAddressFormGroup.controls[str].setErrors({
                  required: Validators.required,
                  apiMessage: obj[key],
                });
              }
            }
            this.processStoreSave = false;
          } else {
            // Other error
            this.processStoreSave = false;
          }
        }
      );
  }

  actionEventUpdateManagerStoreAddressLongLat(): void {
    this.usersService
      .updateManagerStoreAddress(this.storeAddressFormGroup.value, this.storeAddressFormGroup.value.id)
      .subscribe(
        (result) => {
          this.isStoreAddressCreated = true;
          this.getManagerUser(this.userId);
          this.processStoreSave = false;
          this.agmMap.loadDistances();
          this.refreshMap();
          PNotify.success({
            title: "Success",
            text: "Manager store address successfully updated.",
          });
        },
        (error) => {
          if (error.status === 400) {
            const obj = error.error.errors;
            for (const key in obj) {
              if (obj.hasOwnProperty(key)) {
                let str: string = key;
                str = str[0].toLowerCase() + str.slice(1);
                this.storeAddressFormGroup.controls[str].setErrors({
                  required: Validators.required,
                  apiMessage: obj[key],
                });
              }
            }
            this.processStoreSave = false;
          } else {
            // Other error
            this.processStoreSave = false;
          }
        }
      );
  }

  actionEventCreateManagerStoreActivation(): void {
    if (this.isStoreActivationCreated) {
      this.actionEventUpdateManagerStoreActivation();
    } else {
     
      this.usersService.createManagerStorActivation(this.storeActivationFormGroup.value).subscribe(
        (result) => {
          this.isStoreActivationCreated = true;
          this.getManagerUser(this.userId);
          this.processStoreSave = false;
          PNotify.success({
            title: "Success",
            text: "Manager store activation successfully updated.",
          });
        },
        (error) => {
          if (error.status === 400) {
            const obj = error.error.errors;
            for (const key in obj) {
              if (obj.hasOwnProperty(key)) {
                let str: string = key;
                str = str[0].toLowerCase() + str.slice(1);
                this.storeActivationFormGroup.controls[str].setErrors({
                  required: Validators.required,
                  apiMessage: obj[key],
                });
              }
            }
            this.processStoreSave = false;
          } else {
            // Other error
            this.processStoreSave = false;
          }
        }
      );
    }
  }

  actionEventUpdateManagerStoreActivation(): void {
    this.usersService
      .updateManagerStorActivation(this.storeActivationFormGroup.value, this.storeActivationFormGroup.value.id)
      .subscribe(
        (result) => {
          this.isStoreActivationCreated = true;
          this.getManagerUser(this.userId);
          this.processStoreSave = false;
          PNotify.success({
            title: "Success",
            text: "Manager store activation successfully updated.",
          });
        },
        (error) => {
          if (error.status === 400) {
            const obj = error.error.errors;
            for (const key in obj) {
              if (obj.hasOwnProperty(key)) {
                let str: string = key;
                str = str[0].toLowerCase() + str.slice(1);
                this.storeActivationFormGroup.controls[str].setErrors({
                  required: Validators.required,
                  apiMessage: obj[key],
                });
              }
            }
            this.processStoreSave = false;
          } else {
            // Other error
            this.processStoreSave = false;
          }
        }
      );
  }

  

  buildUserPhoto(user: any): any {

    //
    return user.managerUser.photoFormat + "," + user.managerUser.photo;
  }

  buildFilePhoto(managerFile: any): any {
    return managerFile?.photoFormat + "," + managerFile?.photo;
  }

  userLevel(): boolean {
    return true;
  }

  confirmRemove(content){
    this.modalServe.open(content, { centered: true, size: "md" });
  }

  removeFile(){
    this.loader = true;
    //alert(this.managerFile.managerUserId);
    this.fileUploadService.removeManagerFile(this.managerFile.id).subscribe((result) => {
      this.managerFiles = result;

      PNotify.success({
        title: "File Delete",
        text: "File successfully removed.",
      });
      this.loader = false;
      this.modalServe.dismissAll();
    });
  }

  public fileName: any = "Click here to choose file...";

  public onPhotoChange(event: any) {
    const reader = new FileReader();
    
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      this.fileName = file.name;
      reader.onload = () => {
        this.base64Photo = reader.result;

        this.fileUploadFormGroup.patchValue({
          photoFile: reader.result,
        });

        this.changeDetectorRef.markForCheck();
      };
    }
  }

  openFileUpload(content: any) : void {
    this.uploadModal = this.modalServe.open(content, { centered: true, size: "lg" });
  }

  submitFile() : void {
    this.loader = true;

    this.fileUploadService.PostFileUpload(this.fileUploadFormGroup.value, this.userId, this.user.username + "'s " + this.fileUploadFormGroup.controls.meta.value)
    .subscribe((result) => {
      PNotify.success({
        title: "Success",
        text: "File uploaded successfully!",
      });
      this.fileUploadFormGroup.patchValue({
        photoFile: "",
      })
      this.loader = false;
      this.getManagerFiles(this.userId);
      this.uploadModal.close();
    
    }, error => {
      PNotify.error({
        title: "Failed",
        text: "Error uploading file!",
      });
      this.loader = false;
    });
  }

  downloadImage(photo: any, photoFormat: any, filename: any): void {
    const dataURI = photoFormat + "," + photo;
      saveAs(dataURI, this.user.username + "'s " +  filename);
  }

  openUploadFileModal(uploadmodal) {
    this.modalServe.open(uploadmodal, { centered: true, size: "md" });
  }

  resendImageSecurity(){
    if(confirm(`You are about to generate a new security image. Are you sure you want to continue?`)) {
      this.loader = true;
      this.userService.sendSecurityPIN()
      .subscribe(
        (result) => {
          PNotify.success({
            title: "Security Image Generated Successfully.",
            text: "We have successfully sent the security image to your registered email address.",
          });
          this.loader = false;
        });
    }
  }

  isAdd : boolean;
  openAdjustWallet(openAdjustWalletModal, isAdd) : void {
    this.isAdd = isAdd;
    this.adjustWalletFormGroup.controls.isAdd.patchValue(isAdd);
    this.modalServe.open(openAdjustWalletModal, { centered: true, size: "sm" });
  }

  proceedAdjustment(){
    this.loader = true;
    this.transactionService.adjustWalletBalance(this.adjustWalletFormGroup.value).subscribe((result) => {
      this.adjustWalletFormGroup.controls.adjustmentAmount.patchValue(this.emptyString);
      this.modalServe.dismissAll();

      PNotify.success({
        title: "Wallet Ajustment",
        text: "Wallet balance successfully updated.",
      });
      this.loader = false;
    });
  }

  async getBase64ImageFromUrl(imageUrl) {
    var res = await fetch(imageUrl);
    var blob = await res.blob();
  
    return new Promise((resolve, reject) => {
      var reader  = new FileReader();
      reader.addEventListener("load", function () {
          resolve(reader.result);
      }, false);
  
      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    })
  }
}
