// const ROOT_URL = "https://go.vip-88.com";
const ROOT_URL = window.location.origin;

// const API_URL = "https://vip.govipcenter.com";
// const API_URL = "https://api-uat.govipcenter.com";
// const API_URL = "https://localhost:5001";
// const API_URL = "https://localhost:44376";
 const API_URL = "https://global-api.mylgu.com";
// const API_URL = "https://gvc-api.vip-88.com";///
// const API_URL = "https://franchise-api.juanpay.com.ph";

export const environment = {
  production: true,
  EMAIL_VERIFICATION_REDIRECT: `${ROOT_URL}/account/verification`,
  EMAIL_RESET_PASSWORD_REDIRECT: `${ROOT_URL}/account/resetpassword`,
  EMAIL_RESET_PINCODE_REDIRECT: `${ROOT_URL}/account/resetpincode`,
  ROOT_URL: ROOT_URL,
  WEB_URL: `${ROOT_URL}/tracker/`,
  API_URL: `${API_URL}`,
  API_URL_ADMIN: `${API_URL}`,
  DOMAIN_BASED_ROUTING: false,
  firebaseConfig: {
    apiKey: "AIzaSyCdnpoTUNqea0P7O2amn1ZdbV_zoQsXuJ0",
    authDomain: "vipcomegawallet-prod.firebaseapp.com",
    projectId: "vipcomegawallet-prod",
    storageBucket: "vipcomegawallet-prod.appspot.com",
    messagingSenderId: "69721755972",
    appId: "1:69721755972:web:b4fec8a3b62b6f78550711",
    measurementId: "G-VL63TTQVLL"
  }
  // firebaseConfig :{
  //   apiKey: "AIzaSyDV4oOlVMhBn_xcuEUAA7WJSH6Ku6aHaxU",
  //   authDomain: "vipcomegawallet.firebaseapp.com",
  //   databaseURL: "https://vipcomegawallet-default-rtdb.asia-southeast1.firebasedatabase.app",
  //   projectId: "vipcomegawallet",
  //   storageBucket: "vipcomegawallet.appspot.com",
  //   messagingSenderId: "896452778750",
  //   appId: "1:896452778750:web:681799d853d055b68323af",
  //   measurementId: "G-PW9ELQQF3C"
  // }
};
