<app-loader *ngIf="loader"></app-loader>

<div *ngIf="!loader" [class.isPrinting]="printService.isPrinting" class="col-lg-12 col-md-12 col-sm-12 row">
  <div class="col-lg-12 col-md-12 col-sm-12">
    <div class="card">
      <div class="list list-row">
        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-md d-none d-md-block color-red">
              <span class="text-dark">Request ID: </span> <strong>{{ requestId }}</strong>
            </div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">
              <a style="color: blue" (click)="printReceipt()">Print Receipt <span class="fa fa-print"></span></a> <br />
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" [formControl]="isA4" />
                <label class="form-check-label text-muted" for="flexCheckDefault" style="font-size: 12px">
                  Print via A4
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-lg-6 col-md-6 col-sm-6">
    <div class="card">
      <div class="list list-row">
        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-muted text-sm d-none d-md-block">
              <strong
                >Shopee Xpress<br />
                Shopee Xpress Remittance</strong
              >
            </div>
          </div>
          <!-- <div class="no-wrap">
            <div class="item-feed h-2x">
              <img
                src="https://govipcenterstorage.blob.core.windows.net/govipcenterassets/file45d02d80-bfcf-466d-af80-668db42b8572.png"
                height="50"
                class="img-responsive"
                style="object-fit: cover"
              />
            </div>
          </div> -->
        </div>

        <hr />

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-muted text-sm d-none d-md-block">Status</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x"><app-status-table-data [status]="data?.statusId"></app-status-table-data></div>
          </div>
        </div>

        <div *ngIf="data?.statusId == 3 && roleLevel == 1" class="list-item" data-id="19">
          <div class="no-wrap">
            <div class="item-feed h-2x">
              <button class="btn btn-sm btn-danger w-100" (click)="rejectTransaction()">
                <i class="fa fa-times"></i> &nbsp; Reject
              </button>
            </div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">
              <button class="btn btn-sm btn-success w-100" (click)="completeTransaction()">
                <i class="fa fa-check"></i> &nbsp; Complete
              </button>
            </div>
          </div>
        </div>

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-muted text-sm d-none d-md-block">Created Date</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">{{ data?.createdDate | date: "MMM dd, yyyy hh:mm:ss a" }}</div>
          </div>
        </div>

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-muted text-sm d-none d-md-block">Completed Date</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">{{ data?.completedDate | date: "MMM dd, yyyy hh:mm:ss a" }}</div>
          </div>
        </div>

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-muted text-sm d-none d-md-block">Amount</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">{{ data?.amount | number: "1.2-2" }}</div>
          </div>
        </div>

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-muted text-sm d-none d-md-block">Biler Fee</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">{{ 0 | number: "1.2-2" }}</div>
          </div>
        </div>

        <hr />

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-muted text-sm d-none d-md-block">Total Amount</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">{{ data?.amount | number: "1.2-2" }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-lg-6 col-md-6 col-sm-6">
    <div class="card">
      <div class="list list-row">
        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-muted text-sm d-none d-md-block">
              <strong
                >{{ data?.transactByUser.cashierUser.firstName + " " + data?.transactByUser.cashierUser.lastName }}
                <br />
                {{ data?.transactByUser.email }}</strong
              >
            </div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">
              <img
                src="https://govipcenterstorage.blob.core.windows.net/govipcenterassets/filea7a2449f-a14a-4909-85f3-cf35a4b8b68b.png"
                height="50"
                class="avatar w-40 img-responsive"
                style="object-fit: cover"
              />
            </div>
          </div>
        </div>

        <hr />

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-muted text-sm d-none d-md-block">FMS Driver Name</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">{{ data?.fmsDriverName }}</div>
          </div>
        </div>

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-muted text-sm d-none d-md-block">Lifetime ID</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">{{ data?.lifetimeID }}</div>
          </div>
        </div>

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-muted text-sm d-none d-md-block">Mobile No</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">{{ data?.mobileNo }}</div>
          </div>
        </div>

        <!-- <div class="list-item" data-id="19">
            <div class="flex">
              <div class="item-date text-muted text-sm d-none d-md-block">SPX Rider ID No</div>
            </div>
            <div class="no-wrap">
              <div class="item-feed h-2x">{{ data?.spxRiderID }}</div>
            </div>
        </div>

        <div class="list-item" data-id="19">
              <div class="text-center" style="max-height: 350px; overflow-y: scroll">
                <img
                    *ngIf="data?.riderIDURL"
                    [src]="data?.riderIDURL"
                    alt="VIP"
                    onerror="this.src='https://via.placeholder.com/64x64?text=VIP';"
                    style="width: 85%"
                />
                
                <img
                  *ngIf="!data?.riderIDURL"
                  src="https://via.placeholder.com/64x64?text=VIP"
                  alt="VIP"
                  style="width: 85%"
                />
            </div>
        </div> -->

        <!-- <div *ngIf="!isTicketActive">
          <br/>

          <div class="w-100 d-flex justify-content-end">
            <button class="btn btn-sm btn-warning" style="width: 120px;" (click)="openTicket()"> Open Ticket &nbsp; <i class="fa fa-tag"></i> </button>
        </div>
        <br/> 
        </div> -->
      </div>
    </div>
  </div>

  <!-- 
  <app-ticket-create-transaction 
  *ngIf="isTicketActive"
  [requestId]="requestId"
  transactionType="BILLS_PAYMENT_MULTISYS"
  [username]="data.transactByUser.username">
</app-ticket-create-transaction> -->
</div>

<div #vipLogo hidden>
  <img
    src="https://juanpaycdn.azureedge.net/juanpayassets/LOGO_logowithtagline-removebg-preview.png"
    alt="Go-VIP-Logo-Logo-Small"
    height="80px"
  />
</div>
