<div class="flex scrollable hover overflow-auto" id="sidebar">
  <div class="nav-active-text-primary mt-3">
    <ul class="nav bg">
      <li class="nav-header hidden-folded" *ngIf="!isPartner">
        <span class="text-primary">Main</span>
      </li>
      <li>
        <a *ngIf="isPartnerSunlife" href="#" routerLink="partner-sunlife-grepa/reports">
          <span class="nav-icon"><i class="fa fa-th-list"></i></span>
          <span class="nav-text">Reports</span>
        </a>
      </li>
      <li>
        <a *ngIf="isPartnerVIPProtect" href="#" routerLink="partner-vip-protect/reports">
          <span class="nav-icon"><i class="fa fa-th-list"></i></span>
          <span class="nav-text">Reports</span>
        </a>
      </li>
      <li *ngIf="!isPartner">
        <a href="#" routerLink="{{ roleLevelName }}/">
          <span class="nav-icon"><i class="fa fa-home"></i></span>
          <span class="nav-text">Dashboard</span>
        </a>
      </li>
      <!-- <li *ngIf="!isPartner">
        <a href="#" routerLink="{{ roleLevelName }}/govipcenter-bonanza">
          <span class="nav-icon"><i class="fa fa-medal"></i></span>
          <span class="nav-text">Reach Your Goal</span>
        </a>
      </li> -->
      <li *ngIf="!isPartner">
        <a routerLink="{{ roleLevelName }}/{{ roleLevelName == 'acm' ? 'company' : roleLevelName }}-user-view" class="">
          <span class="nav-icon"><i class="fa fa-user"></i></span>
          <span class="nav-text">View Profile</span>
        </a>
      </li>

      <li class="nav-header hidden-folded" *ngIf="!isPartner">
        <span class="text-primary">Transaction</span>
      </li>
      <li *ngIf="outletTransactionHistoryModule">
        <a routerLink="{{ roleLevelName }}/outlet-transaction-history" class="">
          <span class="nav-icon"><i class="fa fa-archive"></i></span>
          <span class="nav-text">Outlet Transaction History</span>
        </a>
      </li>

      <li *ngIf="iglooTransactionHistoryModule">
        <a routerLink="{{ roleLevelName }}/igloo-admin-transactions" class="">
          <span class="nav-icon"><i class="fa fa-archive"></i></span>
          <span class="nav-text">Igloo</span>
        </a>
      </li>
      <li *ngIf="soaModule">
        <a href="#soa" class="" data-toggle="collapse" aria-expanded="false">
          <span class="nav-icon"><i class="fa fa-archive"></i></span>
          <span class="nav-text">Statement of Account</span>
          <span class="nav-caret"></span>
        </a>
        <ul class="collapse list-unstyled" id="soa">
          <li *ngIf="individualSoaModule">
            <a
              routerLink="{{ roleLevelName }}/{{
                roleLevelName == 'acm' ? 'transaction-list-acm' : 'transaction-list-acm'
              }}"
              class=""
            >
              <span class="nav-text">Fund History</span>
            </a>
          </li>
          <!-- <li *ngIf="allSoaModule">
            <a *ngIf="roleLevelId == 1" routerLink="acm/transaction-list-all" class="">
              <span class="nav-text">Global Transaction</span>
            </a>
          </li> -->
          <li *ngIf="transactionHistoryModule">
            <a routerLink="{{ roleLevelName }}/transaction-history" class="">
              <span class="nav-text">Transaction History</span>
            </a>
          </li>

          <li *ngIf="vipcoRewardsTransactionHistory">
            <a routerLink="{{ roleLevelName }}/vipco-transaction-history" class="">
              <span class="nav-text">VIPCO Transaction History</span>
            </a>
          </li>
          <li *ngIf="cashierHistoryModule">
            <a routerLink="{{ roleLevelName }}/pos-transaction-list">
              <span class="nav-text">Cashier History</span>
            </a>
          </li>
          <li *ngIf="serviceChargeHistoryModule">
            <a routerLink="{{ roleLevelName }}/service-charge-history" class="">
              <span class="nav-text">Service Charge History</span>
            </a>
          </li>

          <!-- <li *ngIf="paygramLedgerModule">
            <a href="#" routerLink="{{ roleLevelName }}/paygram-ledger">
              <span class="nav-text">Paygram Ledger History</span>
            </a>
          </li> -->
        </ul>
      </li>

      <li *ngIf="juanpayOutletModule" class="nav-header hidden-folded">
        <span class="text-primary">Module</span>
      </li>
      <li *ngIf="juanpayOutletModule">
        <a href="#" routerLink="{{ roleLevelName }}/juanpay-outlet">
          <span class="nav-icon"><i class="fa fa-sign-language"></i></span>
          <span class="nav-text">JuanPay Outlets</span>
        </a>
      </li>

      <li *ngIf="fundsModule" class="nav-header hidden-folded">
        <span class="text-primary">Funds</span>
      </li>
      <li *ngIf="requestFundsModule">
        <a href="#" routerLink="{{ roleLevelName }}/request-fund-list">
          <span class="nav-icon"><i class="fa fa-sign-language"></i></span>
          <span class="nav-text">Request Funds</span>
        </a>
      </li>

      <!-- <li *ngIf="paygramFundModule">
        <a href="#" routerLink="{{ roleLevelName }}/paygram-fund">
          <span class="nav-icon">
            <img src="../../../assets/icons/paygram-square_btran_1500.png" height="25px" width="25px"
          /></span>
          <span class="nav-text">Paygram Funds</span>
        </a>
      </li> -->

      <li *ngIf="allocateFundsModule">
        <a href="#" routerLink="{{ roleLevelName }}/allocate-fund-list">
          <span class="nav-icon"><i class="fa fa-address-book"></i></span>
          <span class="nav-text">Allocate Funds</span>
        </a>
      </li>

      <li *ngIf="riderCreditModule">
        <a href="#" routerLink="{{ roleLevelName }}/vips-rider-credit">
          <span class="nav-icon"><i class="fa fa-credit-card"></i></span>
          <span class="nav-text">Rider Credit</span>
        </a>
      </li>

      <li *ngIf="salaryModule && getAuthorize()">
        <a href="#" routerLink="{{ roleLevelName }}/salary">
          <span class="nav-icon"><i class="fa fa-credit-card"></i></span>
          <span class="nav-text">Salary</span>
        </a>
      </li>

      <li *ngIf="settlementTitleModule" class="nav-header hidden-folded">
        <span class="text-primary">Partner</span>
      </li>

      <li *ngIf="settlementModule">
        <a href="#settlement" class="" data-toggle="collapse" aria-expanded="false">
          <span class="nav-icon"><i class="fa fa-th-list"></i></span>
          <span class="nav-text">Settlements</span>
          <span class="nav-caret"></span>
        </a>
        <ul class="collapse list-unstyled" id="settlement">
          <li *ngIf="settlementModule">
            <a class="" routerLink="{{ roleLevelName }}/easydebit-settlement">
              <span class="nav-text">Easy Debit</span>
            </a>
          </li>

          <li *ngIf="settlementModule">
            <a class="" routerLink="{{ roleLevelName }}/nationlink-settlement">
              <span class="nav-text">Nationlink</span>
            </a>
          </li>

          <li *ngIf="settlementModule">
            <a routerLink="{{ roleLevelName }}/encash-settlement">
              <span class="nav-text">Encash</span>
            </a>
          </li>

          <li *ngIf="settlementModule">
            <a routerLink="{{ roleLevelName }}/shopee-settlement">
              <span class="nav-text">Shopee</span>
            </a>
          </li>

          <li *ngIf="settlementModule">
            <a class="">
              <span class="nav-text">Lazada</span>
            </a>
          </li>

          <li *ngIf="spxModule">
            <a routerLink="{{ roleLevelName }}/spx-rider-pay-settlement">
              <span class="nav-text">SPX Rider Remit</span>
            </a>
          </li>
        </ul>
      </li>

      <li *ngIf="partnerModule">
        <a href="#report" *ngIf="partnerModule" class="" data-toggle="collapse" aria-expanded="false">
          <span class="nav-icon"><i class="fa fa-list-alt"></i></span>
          <span class="nav-text">Reports</span>
          <span class="nav-caret"></span>
        </a>
        <ul class="collapse list-unstyled" id="report">
          <li *ngIf="partnerModule">
            <a class="" routerLink="{{ roleLevelName }}/sun-life-grepa-reports">
              <span class="nav-text">Sunlife Grepa</span>
            </a>
          </li>
          <li *ngIf="partnerModule">
            <a class="" routerLink="{{ roleLevelName }}/vip-protect-reports">
              <span class="nav-text">VIP Protect</span>
            </a>
          </li>
        </ul>
      </li>

      <li>
        <a href="#pro" *ngIf="settingsModule" class="" data-toggle="collapse" aria-expanded="false">
          <span class="nav-icon"><i class="fa fa-cubes"></i></span>
          <span class="nav-text">Products</span>
          <span class="nav-caret"></span>
        </a>
        <ul class="collapse list-unstyled" id="pro">
          <li *ngIf="vipProtectModule">
            <a routerLink="{{ roleLevelName }}/vip-protect">
              <span class="nav-text">VIP Protect</span>
            </a>
          </li>
          <li *ngIf="da5CertificateModule">
            <a routerLink="{{ roleLevelName }}/da5-certificate">
              <span class="nav-text">BSP Certification</span>
            </a>
          </li>
          <li *ngIf="spxModule">
            <a class="" routerLink="{{ roleLevelName }}/spx-rider-list">
              <span class="nav-text">SPX Rider List</span>
            </a>
          </li>
        </ul>
      </li>

      <li *ngIf="settingsModule">
        <a href="#vips" class="" data-toggle="collapse" aria-expanded="false">
          <span class="nav-icon"><img src="../../../assets/vips2/1.png" height="25px" width="25px" /></span>
          <span class="nav-text">Vips</span>
          <span class="nav-caret"></span>
        </a>
        <ul class="collapse list-unstyled" id="vips">
          <li *ngIf="ridertVerification">
            <a routerLink="{{ roleLevelName }}/vips-admin">
              <span class="nav-text">Vips Admin</span>
            </a>
          </li>

          <li *ngIf="userVipsModule">
            <a href="#user" class="" data-toggle="collapse" aria-expanded="false">
              <span class="nav-text">User</span>
              <span class="nav-caret"></span>
            </a>
            <ul class="collapse list-unstyled" id="user">
              <li>
                <a routerLink="{{ roleLevelName }}/vips-user-list">
                  <span class="nav-text">Vips User List</span>
                </a>
              </li>
              <li *ngIf="govipxAccountVerification">
                <a routerLink="{{ roleLevelName }}/govipx-account-verification">
                  <span class="nav-text">Vips User Level 1</span>
                </a>
              </li>
              <li *ngIf="govipxAccountVerification">
                <a routerLink="{{ roleLevelName }}/vips-level2-verification">
                  <span class="nav-text">Vips User Level 2</span>
                </a>
              </li>
            </ul>
          </li>

          <li *ngIf="merchantVerification">
            <a href="#merchant" class="" data-toggle="collapse" aria-expanded="false">
              <span class="nav-text">Merchant</span>
              <span class="nav-caret"></span>
            </a>
            <ul class="collapse list-unstyled" id="merchant">
              <li>
                <a routerLink="{{ roleLevelName }}/merchant-verification">
                  <span class="nav-text">Merchant Verification</span>
                </a>
              </li>
              <li>
                <a routerLink="{{ roleLevelName }}/hotel-verification">
                  <span class="nav-text">Hotel Verification</span>
                </a>
              </li>
              <li>
                <a routerLink="{{ roleLevelName }}/vips-merchant-store-application">
                  <span class="nav-text">Store Verification</span>
                </a>
              </li>
              <li>
                <a routerLink="{{ roleLevelName }}/merchant-location">
                  <span class="nav-text">Merchant Location</span>
                </a>
              </li>
              <li>
                <a routerLink="{{ roleLevelName }}/merchant-application-history">
                  <span class="nav-text">Merchant List</span>
                </a>
              </li>
            </ul>
          </li>

          <li *ngIf="ridertVerification">
            <a href="#rider" class="" data-toggle="collapse" aria-expanded="false">
              <span class="nav-text">Rider</span>
              <span class="nav-caret"></span>
            </a>
            <ul class="collapse list-unstyled" id="rider">
              <li>
                <a routerLink="{{ roleLevelName }}/rider-verification">
                  <span class="nav-text">Vips Rider Verification</span>
                </a>
              </li>
              <li>
                <a routerLink="{{ roleLevelName }}/rider-application-history">
                  <span class="nav-text">Rider List</span>
                </a>
              </li>
            </ul>
          </li>

          <li *ngIf="accountReactivationModule">
            <a routerLink="{{ roleLevelName }}/account-reactivation">
              <span class="nav-text">Account Reactivation</span>
            </a>
          </li>

          <li *ngIf="vipsCustomizationListModule">
            <a class="" href="#" routerLink="{{ roleLevelName }}/vips-custom-list">
              <span class="nav-text">Vips Customization</span>
            </a>
          </li>

          <li *ngIf="vipsCommunityListModule">
            <a routerLink="{{ roleLevelName }}/vips-community">
              <span class="nav-text">Vips Community</span>
            </a>
          </li>

          <li *ngIf="foodOrderHistoryModule">
            <a routerLink="{{ roleLevelName }}/food-order-history">
              <span class="nav-text">Food Order History</span>
            </a>
          </li>
          <li *ngIf="foodOrderHistoryModule">
            <a routerLink="{{ roleLevelName }}/vips-xpress-history">
              <span class="nav-text">Vips Xpress History</span>
            </a>
          </li>
        </ul>
      </li>

      <li *ngIf="vipMarketPlaceModule" class="nav-header hidden-folded">
        <span class="text-primary">JuanPay Marketplace</span>
      </li>

      <li *ngIf="marketPlaceModule">
        <a class="" routerLink="{{ roleLevelName }}/market-place">
          <span class="nav-icon"><i class="fa fa-store"></i></span>
          <span class="nav-text">Marketplace</span>
        </a>
      </li>
      <li *ngIf="govipStoreModule">
        <a class="" routerLink="{{ roleLevelName }}/govip-store">
          <span class="nav-icon"><i class="fa fa-shopping-bag"></i></span>
          <span class="nav-text">JuanPay Store</span>
        </a>
      </li>
      <li *ngIf="govipStoreModule">
        <a class="" routerLink="{{ roleLevelName }}/purchase-order">
          <span class="nav-icon"><i class="fa fa-cart-arrow-down"></i></span>
          <span class="nav-text">My Purchases</span>
        </a>
      </li>
      <li *ngIf="adminPOModule">
        <a class="" routerLink="{{ roleLevelName }}/purchase-order">
          <span class="nav-icon"><i class="fa fa-cart-arrow-down"></i></span>
          <span class="nav-text">Purchase Order</span>
        </a>
      </li>
      <li *ngIf="applicationModule">
        <a class="" routerLink="{{ roleLevelName }}/application-list">
          <span class="nav-icon"><i class="fa fa-th-large"></i></span>
          <span class="nav-text">Applications</span>
        </a>
      </li>

      <li *ngIf="companyModule" class="nav-header hidden-folded">
        <span class="text-primary">Company</span>
      </li>

      <li *ngIf="companyUserModule">
        <a href="#" routerLink="{{ roleLevelName }}/company-user-list">
          <span class="nav-icon"><i class="fa fa-user-secret"></i></span>
          <span class="nav-text">Admin Users</span>
        </a>
      </li>

      <li *ngIf="managerUserModule">
        <a href="#" routerLink="{{ roleLevelName }}/manager-user-list">
          <span class="nav-icon"><i class="fa fa-user-tie"></i></span>
          <span class="nav-text">Manager Users</span>
        </a>
      </li>

      <li *ngIf="managerUserFinanceModule">
        <a href="#managerUser" class="" data-toggle="collapse" aria-expanded="false">
          <span class="nav-icon"><i class="fa fa-user-tie"></i></span>
          <span class="nav-text">Manager</span>
          <span class="nav-caret"></span>
        </a>
        <ul class="collapse list-unstyled" id="managerUser">
          <li>
            <a href="#" routerLink="{{ roleLevelName }}/manager-user-list">
              <span class="nav-text">Manager Users</span>
            </a>
          </li>
          <li>
            <a href="#" routerLink="{{ roleLevelName }}/manager-wallet">
              <span class="nav-text">Manager Wallet</span>
            </a>
          </li>
        </ul>
      </li>

      <li *ngIf="cashierUserModule">
        <a href="#" routerLink="{{ roleLevelName }}/cashier-user-list">
          <span class="nav-icon"><i class="fa fa-chalkboard-teacher"></i></span>
          <span class="nav-text">Cashier Users</span>
        </a>
      </li>

      <li *ngIf="salesUserModule">
        <a href="#sales" class="" data-toggle="collapse" aria-expanded="false">
          <span class="nav-icon"><i class="fa fa-users"></i></span>
          <span class="nav-text">Sales User</span>
          <span class="nav-caret"></span>
        </a>
        <ul class="collapse list-unstyled" id="sales">
          <li *ngIf="executiveDirectorModule">
            <a routerLink="{{ roleLevelName }}/executive-director-user-list">
              <span class="nav-text">Executive Director</span>
            </a>
          </li>

          <li *ngIf="salesDirectorModule">
            <a routerLink="{{ roleLevelName }}/sales-director-user-list">
              <span class="nav-text">Sales Director</span>
            </a>
          </li>

          <li *ngIf="salesManagerModule">
            <a routerLink="{{ roleLevelName }}/sales-manager-user-list">
              <span class="nav-text">Sales Manager</span>
            </a>
          </li>

          <li *ngIf="salesAgentModule">
            <a routerLink="{{ roleLevelName }}/sales-agent-user-list">
              <span class="nav-text">Sales Agent</span>
            </a>
          </li>
        </ul>
      </li>

      <li *ngIf="storeModule" class="nav-header hidden-folded">
        <span class="text-primary">Store</span>
      </li>
      <li *ngIf="posModule">
        <a class="" href="/pos-transaction">
          <span class="nav-icon"><i class="fa fa-desktop"></i></span>
          <span class="nav-text">Point of Sales</span>
        </a>
      </li>
      <!-- <li *ngIf="vipCloudModule">
        <a class="" href="{{ roleLevelName }}/vip-cloud">
          <span class="nav-icon"><i class="fa fa-cloud"></i></span>
          <span class="nav-text">VIP Cloud</span>
        </a>
      </li>
      <li *ngIf="vipLootModule">
        <a class="" href="{{ roleLevelName }}/vip-loot">
          <span class="nav-icon"><i class="fa fa-coins"></i></span>
          <span class="nav-text">VIP Loot</span>
        </a>
      </li> -->

      <!-- Vips Module -->

      <li *ngIf="vipsModule" class="ml-0 pl-0 nav-header hidden-folded">
        <span class="text-primary">
          <img src="../../../assets/vips2/1.png" height="25px" width="25px" /> Community</span
        >
      </li>
      <li *ngIf="vipsModule">
        <a href="#vips" class="" data-toggle="collapse" aria-expanded="false">
          <span class="nav-icon"><i class="fa fa-home"></i></span>
          <span class="nav-text">Vips</span>
          <span class="nav-caret"></span>
        </a>
        <ul class="collapse list-unstyled" id="vips">
          <li>
            <a class="" href="#" routerLink="{{ roleLevelName }}/vips-dashboard">
              <span class="nav-text">Dashboard</span>
            </a>
          </li>
          <li>
            <a class="" href="#" routerLink="{{ roleLevelName }}/vips-profile">
              <span class="nav-text">Profile</span>
            </a>
          </li>
          <li>
            <a class="" href="#" routerLink="{{ roleLevelName }}/vips-community">
              <span class="nav-text">Community</span>
            </a>
          </li>
          <!-- <li>
            <a class="" href="#" routerLink="{{ roleLevelName }}/vips-topup">
              <span class="nav-text">Topup</span>
            </a>
          </li>
          <li>
            <a class="" href="#" routerLink="{{ roleLevelName }}/vips-send">
              <span class="nav-text">Send</span>
            </a>
          </li> -->
          <li>
            <a class="" href="#" routerLink="{{ roleLevelName }}/vips-tutorials">
              <span class="nav-text">Tutorials</span>
            </a>
          </li>
        </ul>
      </li>
      <li *ngIf="vipsModule">
        <a href="#" routerLink="{{ roleLevelName }}/vips-users">
          <span class="nav-icon"><i class="fa fa-users"></i></span>
          <span class="nav-text">Vips User</span>
        </a>
      </li>
      <li *ngIf="vipsModule">
        <a href="#" routerLink="{{ roleLevelName }}/vips-merchants">
          <span class="nav-icon"><i class="fa fa-shopping-bag"></i></span>
          <span class="nav-text">Vips Merchant</span>
        </a>
      </li>

      <li *ngIf="vipsModule">
        <a href="#" routerLink="{{ roleLevelName }}/vips-riders">
          <span class="nav-icon"><i class="fa fa-motorcycle"></i></span>
          <span class="nav-text">Vips Riders</span>
        </a>
      </li>
      <li *ngIf="vipsRequestFundModule">
        <a href="#" routerLink="{{ roleLevelName }}/request-fund-list">
          <span class="nav-icon"><i class="fa fa-sign-language"></i></span>
          <span class="nav-text">Request Funds</span>
        </a>
      </li>
      <li *ngIf="vipsRiderCreditModule">
        <a href="#" routerLink="{{ roleLevelName }}/vips-rider-credit">
          <span class="nav-icon"><i class="fa fa-credit-card"></i></span>
          <span class="nav-text">Rider Credit</span>
        </a>
      </li>
      <!-- <li *ngIf="vipsModule">
        <a>
          <span class="nav-icon"><i class="fa fa-globe"></i></span>
          <span class="nav-text">Vips Service Providers</span>
        </a>
      </li>
      <li *ngIf="vipsModule">
        <a>
          <span class="nav-icon"><i class="fa fa-comments"></i></span>
          <span class="nav-text">instant Messaging</span>
        </a>
      </li>

      <li *ngIf="vipsModule">
        <a href="#specialFunction" class="" data-toggle="collapse" aria-expanded="false">
          <span class="nav-icon"><i class="fa fa-code"></i></span>
          <span class="nav-text">Special Functions</span>
          <span class="nav-caret"></span>
        </a>
        <ul class="collapse list-unstyled" id="specialFunction">
          <li *ngIf="vipsModule">
            <a href="#customRequest" class="" data-toggle="collapse" aria-expanded="false">
              <span class="nav-icon"><i class="fa fa-object-group"></i></span>
              <span class="nav-text">Request Custom Vips</span>
              <span class="nav-caret"></span>
            </a>
            <ul class="collapse list-unstyled" id="customRequest">
              <li>
                <a class="">
                  <span class="nav-text">Request</span>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </li> -->

      <!-- Vips Module End -->

      <li *ngIf="settingsModule" class="nav-header hidden-folded">
        <span class="text-primary">Settings</span>
      </li>

      <li *ngIf="settingsModule">
        <a href="#webSetting" class="" data-toggle="collapse" aria-expanded="false">
          <span class="nav-icon"><i class="fa fa-laptop"></i></span>
          <span class="nav-text">Web Settings</span>
          <span class="nav-caret"></span>
        </a>
        <ul class="collapse list-unstyled" id="webSetting">
          <li *ngIf="webSettingsModule">
            <a class="" routerLink="{{ roleLevelName }}/web-settings-list">
              <span class="nav-icon"><i class="fa fa-cog"></i></span>
              <span class="nav-text">Web Settings</span>
            </a>
          </li>

          <li *ngIf="realtimeMessageModule">
            <a class="" routerLink="{{ roleLevelName }}/realtime-message">
              <span class="nav-icon"><i class="fa fa-eye"></i></span>
              <span class="nav-text">Message Blast Scheduling</span>
            </a>
          </li>
          <li *ngIf="locationTrackerModule">
            <a class="" routerLink="{{ roleLevelName }}/location-tracker">
              <span class="nav-icon"><i class="fa fa-map-marker"></i></span>
              <span class="nav-text">Location Tracker</span>
            </a>
          </li>
          <li *ngIf="migrationModule">
            <a class="" routerLink="{{ roleLevelName }}/migration-list">
              <span class="nav-icon"><i class="fa fa-user-plus"></i></span>
              <span class="nav-text">Migration List</span>
            </a>
          </li>
          <li *ngIf="salesMigrationModule">
            <a class="" routerLink="{{ roleLevelName }}/migration-sales-list">
              <span class="nav-icon"><i class="fa fa-user-plus"></i></span>
              <span class="nav-text">Sales Migration List</span>
            </a>
          </li>
          <li *ngIf="salesActivationModule">
            <a routerLink="{{ roleLevelName }}/sales-activation-list">
              <span class="nav-icon"><i class="fa fa-toggle-on"></i></span>
              <span class="nav-text">Activation Center</span>
            </a>
          </li>

          <!-- <li *ngIf="passwordResetModule">
            <a class="">
              <span class="nav-icon"><i class="fa fa-asterisk"></i></span>
              <span class="nav-text">Password Reset Logs</span>
            </a>
          </li> -->
          <!-- <li *ngIf="logsModule">
            <a class="">
              <span class="nav-icon"><i class="fa fa-th-list"></i></span>
              <span class="nav-text">Logs</span>
            </a>
          </li> -->
          <!-- <li *ngIf="auditCheckerModule">
            <a class="" routerLink="{{ roleLevelName }}/audit-wallet">
              <span class="nav-icon"><i class="fa fa-terminal"></i></span>
              <span class="nav-text">Audit Checker</span>
            </a>
          </li> -->
          <li *ngIf="announcenmentModule">
            <a class="" routerLink="{{ roleLevelName }}/announcement-builder">
              <span class="nav-icon"><i class="fa fa-bullhorn"></i></span>
              <span class="nav-text">Announcement Builder</span>
            </a>
          </li>
        </ul>
      </li>

      <li *ngIf="storeSettings">
        <a href="#storeSettings" class="" data-toggle="collapse" aria-expanded="false">
          <span class="nav-icon"><i class="fa fa-chalkboard-teacher"></i></span>
          <span class="nav-text">Store Settings</span>
          <span class="nav-caret"></span>
        </a>
        <ul class="collapse list-unstyled" id="storeSettings">
          <li *ngIf="storeSettings">
            <a class="" routerLink="{{ roleLevelName }}/store-settings">
              <span class="nav-icon"><i class="fa fa-cog"></i></span>
              <span class="nav-text">Item Settings</span>
            </a>
          </li>

          <li *ngIf="storeSettings">
            <a class="" routerLink="{{ roleLevelName }}/stock-item-list">
              <span class="nav-icon"><i class="fa fa-magnet"></i></span>
              <span class="nav-text">Stock Items</span>
            </a>
          </li>

          <li *ngIf="inventoryModule">
            <a class="" routerLink="{{ roleLevelName }}/inventory">
              <span class="nav-icon"><i class="fa fa-cubes"></i></span>
              <span class="nav-text">Item Inventory</span>
            </a>
          </li>
        </ul>
      </li>

      <li *ngIf="accessManagementModule" class="nav-header hidden-folded">
        <span class="text-primary">Access Management</span>
      </li>

      <li *ngIf="roleModule">
        <a href="#role" class="" data-toggle="collapse" aria-expanded="false">
          <span class="nav-icon"><i class="fa fa-user-plus"></i></span>
          <span class="nav-text">Role</span>
          <span class="nav-caret"></span>
        </a>
        <ul class="collapse list-unstyled" id="role">
          <li *ngIf="manageRoleModule">
            <a routerLink="{{ roleLevelName }}/role-list">
              <span class="nav-text">Manage Roles</span>
            </a>
          </li>
        </ul>
      </li>

      <li *ngIf="supportModule" class="nav-header hidden-folded">
        <span class="text-primary">Support</span>
      </li>

      <li *ngIf="ticketModule">
        <a href="#ticket" class="" data-toggle="collapse" aria-expanded="false">
          <span class="nav-icon"><i class="fa fa-object-group"></i></span>
          <span class="nav-text">Ticket</span>
          <span class="nav-caret"></span>
        </a>
        <ul class="collapse list-unstyled" id="ticket">
          <li>
            <a class="">
              <span class="nav-text">New Ticket</span>
            </a>
          </li>
          <li>
            <a class="">
              <span class="nav-text">Open Ticket</span>
            </a>
          </li>
          <li>
            <a class="">
              <span class="nav-text">Close Ticket</span>
            </a>
          </li>
        </ul>
      </li>
      <!-- <li *ngIf="discussionModule">
        <a>
          <span class="nav-icon"><i class="fa fa-map"></i></span>
          <span class="nav-text">Discussion</span>
        </a>
      </li> -->
      <li *ngIf="libraryModule">
        <a href="#library" class="" data-toggle="collapse" aria-expanded="false">
          <span class="nav-icon"><i class="fa fa-paperclip"></i></span>
          <span class="nav-text">Library</span>
          <span class="nav-caret"></span>
        </a>
        <ul class="collapse list-unstyled" id="library">
          <li *ngIf="downloadAreModule">
            <a
              class=""
              href="https://sites.google.com/acmbusinesssolutions.net/govipcenter-migration/download-area"
              target="_blank"
            >
              <span class="nav-text">Download Area</span>
            </a>
          </li>
          <li *ngIf="uploadAreModule">
            <a class="">
              <span class="nav-text">Upload Area</span>
            </a>
          </li>
        </ul>
      </li>
      <li *ngIf="videoTutorialModule">
        <a routerLink="{{ roleLevelName }}/video-collections">
          <span class="nav-icon"><i class="fa fa-play"></i></span>
          <span class="nav-text">Video Tutorial Collections</span>
        </a>
      </li>

      <!-- ############ Sales User ############ -->

      <li *ngIf="salesModule" class="nav-header hidden-folded">
        <span class="text-primary">Rewards</span>
      </li>
      <!-- <li *ngIf="salesModule">
          <a routerLink="{{ roleLevelName }}/store">
            <span class="nav-icon"><i class="fa fa-store"></i></span>
            <span class="nav-text">Store</span>
          </a>
        </li> -->
      <li *ngIf="salesModule">
        <a routerLink="{{ roleLevelName }}/community">
          <span class="nav-icon"><i class="fa fa-users"></i></span>
          <span class="nav-text">Community</span>
        </a>
      </li>
      <li *ngIf="salesModule">
        <a routerLink="{{ roleLevelName }}/sales-activation-list">
          <span class="nav-icon"><i class="fa fa-toggle-on"></i></span>
          <span class="nav-text">Activation Center</span>
        </a>
      </li>
      <!-- <li *ngIf="salesModule">
          <a routerLink="{{ roleLevelName }}/send-vipco">
            <span class="nav-icon"><i class="fa fa-paper-plane"></i></span>
            <span class="nav-text">Send VIPCO</span>
          </a>
        </li> -->

      <li *ngIf="salesModule" class="nav-header hidden-folded">
        <span class="text-primary">Transaction History</span>
      </li>
      <li *ngIf="salesModule" routerLink="{{ roleLevelName }}/vipco-transaction-list">
        <a>
          <span class="nav-icon"><i class="fa fa-coins"></i></span>
          <span class="nav-text">Rewards</span>
        </a>
      </li>
      <li *ngIf="salesModule" routerLink="{{ roleLevelName }}/pairing">
        <a>
          <span class="nav-icon"><i class="fa fa-handshake"></i></span>
          <span class="nav-text">Pairing</span>
        </a>
      </li>
      <!-- ############ Sales User END ############ -->
    </ul>
  </div>
</div>

<!--
</div> -->
<!-- ############ Aside END-->
